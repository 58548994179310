import {FieldUpdateValue, GeneralAction, TableUpdate, TableUpdateValue} from "../ReducerTypes";
import {GeneralActionType} from "./GeneralActionTypes";
import {AuthUser} from "../reducers/GeneralStore";
import {SpitchEvent} from "@spitchlingware/ui-reusable-components/dist/components/General";
import {ElementTypesEnum} from "../../utils/CommonTypes";

export const setAuthUser = (user: AuthUser): GeneralAction<AuthUser> => {
    return {
        type: GeneralActionType.SET_AUTH_USER,
        payload: user
    }
};

export const emitEvent = (event: SpitchEvent): GeneralAction => {
    return {
        type: GeneralActionType.EMIT_EVENT,
        payload: event
    }
};


export const setTableState = (table: ElementTypesEnum, values: Array<TableUpdateValue>): GeneralAction<TableUpdate> => {
    return {
        type: GeneralActionType.SET_TABLE_STATE,
        payload: {
            table,
            values
        }
    }
};

export const setStateField = (name: string, value: any): GeneralAction<FieldUpdateValue> => {
    return {
        type: GeneralActionType.SET_STATE_FIELD,
        payload: {
            name: name,
            value: value
        }
    }

};
