import React, {Dispatch} from 'react';
import {ConfigurableVerticalTable} from "@spitchlingware/ui-reusable-components";
import {ConfigurableContent} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableDetails/ConfigurableDetailsTypes";
import {withTranslation, WithTranslation} from "react-i18next";
import styleList from "./UserDetailsTab.module.scss";
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {Action} from 'redux';
import {AppState, CommonStateProps} from "../../../../../store/ReducerTypes";
import {commonStateProps} from "../../../../../utils/ReducerFunctions";
import {ElementTypesEnum} from "../../../../../utils/CommonTypes";
import {AuthUser} from "../../../../../store/reducers/GeneralStore";
import {ConfigurableVerticalTableConfigType} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableVerticalTable/ConfigurableVerticalTableTypes";
import {validateStringField} from "../../../../../utils/TableUtils";
import {getOrgById} from "../../../../../utils/BackendUtils";
import {OrganizationModel} from "../../../../../model/OrganizationModel";
import createValidatorFromOrg from "../../../../../utils/PasswordValidator";
import {isUserEditable} from "../../UsersUtils";

type OwnProps = {};
type StateProps = CommonStateProps & {
    user: AuthUser | undefined
};
type DispatchProps = {};
type Props = ConfigurableContent & WithTranslation & OwnProps & StateProps & DispatchProps;
type State = {
    org: OrganizationModel
}


class UserDetailsTab extends React.Component<Props, State> {

    helpPrefix = 'users';

    handleChange = (name: string, value: any) => {
        this.props.onChange(name, value);
    };

    loadOrgData = () => {
        getOrgById(this.props.element.org, (data, error) => {
            if (!error) {
                this.setState({
                    org: data
                });
            }
        });
    }

    componentDidMount() {
        this.loadOrgData();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.element._id !== this.props.element._id) {
            this.loadOrgData();
        }
    }

    isDataLoaded = () => {
        return this.state?.org;
    }

    detailsTableConfig = (): Array<ConfigurableVerticalTableConfigType> => {
        const {t, orgDetailsConfigField, user, element} = this.props;
        const org = orgDetailsConfigField ? [orgDetailsConfigField] : [];

        const passwordValidator = createValidatorFromOrg(this.state.org);

        const isEditable = isUserEditable(user);

        const isPasswordValid = () => {
            if (!element.password) {
                return {
                    valid: true,
                    errors: []
                }
            }
            return passwordValidator.validate(element.password);
        }

        const getPasswordPolicyError = () => {
            const res = isPasswordValid();
            if (!res.valid) {
                return <span>{
                    res.errors.map((e, i) => <span style={{display: "block"}}
                                                   key={i}>{e}</span>)
                }</span>;
            }
            return undefined;
        }

        const admin: ConfigurableVerticalTableConfigType[] = user?.is_super ? [{
            key: 'isAdmin',
            label: t(`${this.helpPrefix}.details.details_table.rows.isAdmin`),
            type: 'checkbox',
            editable: isEditable
        }] : [];

        return [
            {
                key: '_id',
                label: t(`${this.helpPrefix}.details.details_table.rows.id`),
                type: 'text',
                editable: false
            },
            ...org,
            {
                key: 'firstName',
                label: t(`${this.helpPrefix}.details.details_table.rows.firstname`),
                validate: validateStringField,
                type: 'text',
                editable: isEditable
            },
            {
                key: 'lastName',
                label: t(`${this.helpPrefix}.details.details_table.rows.lastname`),
                validate: validateStringField,
                type: 'text',
                editable: isEditable
            },
            {
                key: 'email',
                label: t(`${this.helpPrefix}.details.details_table.rows.email`),
                validate: validateStringField,
                type: 'text',
                editable: isEditable
            },
            {
                key: 'password',
                label: t(`${this.helpPrefix}.details.details_table.rows.password`),
                type: 'password',
                editable: isEditable,
                validate: () => isPasswordValid().valid,
                helperText: getPasswordPolicyError()
            },
            ...admin,
            {
                key: 'disabled',
                label: t(`${this.helpPrefix}.details.details_table.rows.disabled.label`),
                type: 'checkbox',
                editable: isEditable
            }
        ];
    };

    render() {
        const {element} = this.props;
        const {t} = this.props;
        if (!this.isDataLoaded()) {
            return <span/>;
        }
        if (this.state && this.state.org) {
            const detailsConfig = this.detailsTableConfig();
            return (<div className={styleList.container}>
                <ConfigurableVerticalTable name={t(`${this.helpPrefix}.details.details_table.title`)}
                                           config={detailsConfig}
                                           onChange={this.handleChange}
                                           data={element}
                                           permissions={this.props.permissions}
                                           setValidState={this.props.setValidState}
                />
            </div>)
        } else {
            return <span/>;
        }
    };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (state: AppState, ownProps: OwnProps): StateProps => {
    return {
        ...commonStateProps(state, ElementTypesEnum.USER),
        user: state.general.user
    }
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: Dispatch<Action<any>>, ownProps: OwnProps): DispatchProps => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserDetailsTab))
