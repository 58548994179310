export const validateStringField = (obj: any, fieldName: string) => {
    const value = obj[fieldName];
    return validateString(value);
};

export const validateUniqueField = (obj: any, fieldName: string, elements: any[]) => {
    if (!validateStringField(obj, fieldName)) {
        return false;
    }
    const idx = elements.findIndex(e => e[fieldName] === obj[fieldName] && (e._id !== obj._id || !e._id));
    return idx === -1;
};

export const validateString = (value: string | undefined): boolean => {
    return value !== undefined && value !== null && value !== '';
};
