import {DataActionType} from "./DataActionTypes";
import {Dispatch} from "react";
import {APThunkResult, DataAction} from "../ReducerTypes";
import {backend} from "../../utils/Backend";
import {ElementTypesEnum} from "../../utils/CommonTypes";
import i18n from "../../i18n";
import {notify} from "../../utils/NotifierHelpers";
import {setStateField} from "./GeneralActions";
import {SessionService} from "../../services/SessionService";
import {AxiosError} from "axios";

export const loadGenerics = (type: ElementTypesEnum): APThunkResult => {
    return (dispatch: Dispatch<DataAction>, getState): void => {
        const params: Record<string, any> = {};
        const org = getState().general.organization;
        if (org && type !== ElementTypesEnum.ORGANIZATION) {
            params["org"] = org;
        }
        backend.get(type, params)
            .then((res) => {
                const {data} = res;
                dispatch({
                    type: DataActionType.LOAD_GENERIC,
                    elementType: type,
                    payload: data
                });
                notify(dispatch, `${i18n.t('messages.data.loaded')} "${type}"`, 'success');
            })
            .catch((err) => {
                notify(dispatch, `${i18n.t('messages.data.error_load')} "${type}"`, 'error');
                console.log(err);
            })
    }
};

export const updateGenericElement = (element: any, type: ElementTypesEnum, callback?: (element: any) => void): APThunkResult => {
    return (dispatch: Dispatch<DataAction>, getState, arg): void => {
        const {user, organization} = getState().general;
        if (user?.is_super) {
            if (!element.org)
                element.org = organization;
        } else {
            element.org = user?.org_id;
        }

        backend.post(type, element)
            .then(res => {
                const {data} = res;
                dispatch({
                    type: DataActionType.POST_GENERIC,
                    elementType: type,
                    payload: data
                });
                if (callback) callback(data);
            })
            .catch((err) => {
                let msg = "";
                if (err instanceof AxiosError && err.response?.data?.customMessage) {
                    msg = err.response.data.customMessage;
                } else {
                    msg = `${i18n.t('messages.data.error_save')} "${type}"`
                }
                console.log(err);
                notify(dispatch, msg, 'error');
            })
    }
};

export const deleteGenericElement = (element: any, type: ElementTypesEnum, callback?: (element: any) => void): APThunkResult => {
    return (dispatch: Dispatch<DataAction>, getState, arg): void => {
        backend.delete(type, element._id)
            .then(res => {
                dispatch({
                    type: DataActionType.DELETE_GENERIC,
                    elementType: type,
                    payload: element
                });
                if (callback) callback(element);
            })
            .catch(err => {
                notify(dispatch, `${i18n.t('messages.data.error_delete')} "${type}"`, 'error');
                console.log(err);
            })
    }
};

export const loadAllElements = (org: string): APThunkResult => {
    return (dispatch: Dispatch<any>, getState): void => {
        Promise.allSettled(Object.keys(ElementTypesEnum).map((key: string) => ElementTypesEnum[key as keyof typeof ElementTypesEnum])
            .map(async (elementType, elIndex) => {
                const params: Record<string, any> = {};
                if (org && elementType !== ElementTypesEnum.ORGANIZATION) {
                    params["org"] = org;
                }
                return backend.get(elementType, params).then(res => {
                    return new Promise((resolve, reject) => {
                        const {data} = res;
                        resolve({
                            type: DataActionType.LOAD_GENERIC,
                            elementType: elementType,
                            payload: data
                        })
                    })
                }).catch(reason => {
                    notify(dispatch, `${i18n.t('messages.data.error_load')} "${elementType}"`, 'error');
                    console.log(reason);
                })
            })).then(results => {
            let hasError = false;
            results.forEach((res: any) => {
                if (res.status === "fulfilled") {
                    dispatch(res.value);
                } else {
                    hasError = true;
                }
            });
            if(hasError) {
                notify(dispatch, i18n.t('messages.data.error_load'), 'error');
            } else {
                notify(dispatch, i18n.t('messages.data.all_loaded'), 'success');
            }
            dispatch(setStateField("organization", org));
            SessionService.setOrg(org);
        })
    }
}
