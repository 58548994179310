import React from "react";
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Theme
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {OrganizationModel} from "../../model/OrganizationModel";
import {makeStyles} from "@material-ui/core/styles";

type Props = {
    open: boolean
    onClose: () => void
    onSelect: (id: string) => void
    items: OrganizationModel[]
    selected: string
}

const OrgSelectionDialog: React.FC<Props> = (props) => {
    const [selected, setSelected] = React.useState(props.selected);

    const {t} = useTranslation();
    const styles = useStyles();
    return (
        <div>
            <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t(`toolbar.select_org.title`)}</DialogTitle>
                <DialogContent className={styles.root}>
                    <Select
                        className={styles.select}
                        value={selected}
                        onChange={(evt) => setSelected(evt.target.value + "")}
                        displayEmpty>
                        <MenuItem key="none" value={""}>{t(`toolbar.select_org.not_selected`)}</MenuItem>
                        {props.items.map(o => <MenuItem key={o._id} value={o._id}>{o.name}</MenuItem>)}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="primary">
                        {t(`toolbar.select_org.buttons.cancel`)}
                    </Button>
                    <Button onClick={() => props.onSelect(selected)} color="primary">
                        {t(`toolbar.select_org.buttons.ok`)}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            minWidth: '300px'
        },
        select: {
            flex: 1
        }
    }),
);

export default OrgSelectionDialog
