import {SelectOption} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableVerticalTable/ConfigurableVerticalTableTypes";
import i18n from "../i18n";

const t = i18n.getFixedT("");

export const clone = (destination: any, source: any) => {
    // skip null, undefined and primitive types
    if (null === source || undefined === source || "object" !== typeof source) return;

    // Обрабатываем поля объекта в цикле
    for (let fieldName in source) {
        const field = source[fieldName];

        if (field instanceof Date) {
            // Если поле тип Date
            const copy = new Date();
            copy.setTime(field.getTime());
            destination[fieldName] = copy;

        } else if (Array.isArray(field)) {
            //Если поле массив
            destination[fieldName] = [];
            for (var i = 0; i < field.length; i++) {
                // Here we check type of element in the array
                if (null === field[i] || undefined === field[i] || "object" !== typeof field[i]) {
                    destination[fieldName][i] = field[i];
                } else {
                    const copy = {};
                    clone(copy, field[i]);
                    destination[fieldName][i] = copy;
                }
            }
        } else if (typeof field === "function") {
            destination[fieldName] = field;
        } else if (field instanceof Object) {
            //Если поле объект
            const copy = {};
            clone(copy, field);
            destination[fieldName] = copy;
        } else {
            //Если поле простой тип
            destination[fieldName] = field;
        }
    }
};

export const getInnerField = (object: any, fields: Array<string>, def: any = ''): any => {
    if (!object) return null;
    if (fields.length === 0) return object;
    const field = fields.shift();
    if (!field) return def;
    if (fields.length === 0) {
        return (object[field] || def);
    } else {
        return getInnerField(object[field], fields)
    }
};


/**
 * Get value from nested object
 * @param data - data to get value from
 * @param path - path to the value, for example prop1.nested_prop2
 * @param def -
 */
export const getValueFromObject = (data: any, path: string, def = ""): any => {
    const paths = path.split(".");
    return getInnerField(data, paths, def);
};

/**
 * Set value for the nested object
 * @param data - object
 * @param path - path to the value, for example prop1.nested_prop2
 * @param value - value
 */
export const setValueForObject = (data: any, path: string, value: any) => {
    const paths = path.split(".");
    const setInner = (data: any, pathArray: Array<string>, value: any) => {
        const path = pathArray.shift();
        if (!path) return null;
        if (pathArray.length === 0) {
            data[path] = value
        } else {
            if (!data[path]) {
                data[path] = isNaN(Number(pathArray[0])) ? {} : [];
            }
            setInner(data[path], pathArray, value)
        }
    };
    setInner(data, paths, value)
};

export const get_process_env = (name: string) => {
    let value = process.env[name];
    if (!value) {
        return ""
    }
    if (value === "window.location.origin") {
        return window.location.origin;
    }
    return value;
};

export const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export const parametrizedOptions = (types: Record<string, string>, group: string): Array<SelectOption> => {
    return Object.keys(types).map(key => {
        const label = t(`${group}.${types[key]}`);
        return {
            value: types[key],
            label,
            name: label,
        }
    });
};
