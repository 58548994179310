import {DataAction} from "../ReducerTypes";
import {DataActionType} from "../actions/DataActionTypes";
import {ElementTypesEnum} from "../../utils/CommonTypes";

export type StateElements = {
    [key in ElementTypesEnum]: Array<any>
}

export type DataStoreState = StateElements;

const initState = (): DataStoreState => {
    const el = Object.keys(ElementTypesEnum)
        .map((key: string) => ElementTypesEnum[key as keyof typeof ElementTypesEnum])
        .reduce((acc: Record<ElementTypesEnum, Array<any>>, key: ElementTypesEnum) => {
            acc[key] = [];
            return acc;
        }, {} as Record<ElementTypesEnum, Array<any>>);
    return {
        ...el
    };
};


const loadData = (state: DataStoreState, elementName: ElementTypesEnum, data: Array<any>): DataStoreState => {
    return {
        ...state,
        [elementName]: data
    }
};

const postElement = (state: DataStoreState, elementName: ElementTypesEnum, data: any): DataStoreState => {
    // @ts-ignore
    if (!state[elementName]) return state;
    // @ts-ignore
    const elements = [...state[elementName]];

    const index = elements.findIndex(el => el._id === data._id);
    if (index === -1) {
        elements.push(data);
    } else {
        elements[index] = data;
    }

    return {
        ...state,
        [elementName]: elements
    }
};

const deleteElement = (state: DataStoreState, elementName: ElementTypesEnum, data: any): DataStoreState => {
    // @ts-ignore
    if (!state[elementName]) return state;
    // @ts-ignore
    const elements = [...state[elementName]];

    const index = elements.findIndex(el => el._id === data._id);
    if (index !== -1) elements.splice(index, 1);

    return {
        ...state,
        [elementName]: elements
    }
};

export const dataReducer = (state: DataStoreState = initState(), action: DataAction): DataStoreState => {
    switch (action.type) {
        case DataActionType.LOAD_GENERIC:
            return loadData(state, action.elementType, action.payload);
        case DataActionType.POST_GENERIC:
            return postElement(state, action.elementType, action.payload);
        case DataActionType.DELETE_GENERIC:
            return deleteElement(state, action.elementType, action.payload);
        default:
            return state;
    }
};