import React, {useState} from "react";
import {Button, TextField} from "@material-ui/core";
import {InputTag} from "@spitchlingware/ui-reusable-components";
import {makeStyles} from "@material-ui/core/styles";
import {Item} from "@spitchlingware/ui-reusable-components/dist/components/Commons/TagInput/Types";

type OwnProps = {
    labels: {
        tag_label_input: string,
        tag_id_input: string,
        add_label: string
    },
    values: Item[],
    onChange: (values: Item[]) => void
}

type Props = OwnProps

const useStyles = makeStyles({
        settingsAddTagPanel: {
            display: "flex",
            flexDirection: "column",
            width: "100%"
        }
    }
);

const KeyValueListEditor: React.FC<Props> = (props) => {

    const [inputTag, setInputTag] = useState<string>('');
    const [inputId, setInputId] = useState<string>('');
    const classes = useStyles();
    const onAdd = () => {
        props.onChange([...props.values, {
            id: inputId,
            value: inputId,
            title: inputTag
        }]);
        setInputTag('');
        setInputId('');
    }
    return (
        <React.Fragment>
            <div className={classes.settingsAddTagPanel}>
                <div>
                    <TextField value={inputTag || ""}
                               label={props.labels.tag_label_input}
                               onChange={e => setInputTag(e.target.value)}/>
                    <TextField value={inputId || ""}
                               label={props.labels.tag_id_input}
                               onChange={e => setInputId(e.target.value)}/>
                    <Button
                        disabled={!inputTag}
                        variant="outlined"
                        color="primary"
                        onClick={onAdd}>
                        {props.labels.add_label}
                    </Button>
                </div>
                <InputTag tags={props.values || []} onChange={(tags: Item[]) => {
                    props.onChange(tags)
                }}/>
            </div>
        </React.Fragment>
    );
}

export default KeyValueListEditor;
