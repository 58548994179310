import {push} from "connected-react-router";
import {AppState, APThunkDispatch, CommonStateProps} from "../store/ReducerTypes";
import {ElementTypesEnum} from "./CommonTypes";
import {
    ConfigurableDispatchProps,
    DetailsDispatchProps,
    DetailsStateProps
} from "@spitchlingware/ui-reusable-components/dist/components/Utils/ConfigurablesHelper";
import {SpitchEvent} from "@spitchlingware/ui-reusable-components/dist/components/General";
import {deleteGenericElement, loadGenerics, updateGenericElement} from "../store/actions/DataActions";
import {emitEvent, setTableState} from "../store/actions/GeneralActions";
import {ConfigurableTableTypes} from "@spitchlingware/ui-reusable-components";
import i18n from '../i18n';
import {SelectOption} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableVerticalTable/ConfigurableVerticalTableTypes";
import {validateStringField} from "./TableUtils";

export const commonDetailsStateProps = (state: AppState, elementType: ElementTypesEnum): DetailsStateProps => {
    const {data} = state;

    return {
        elements: data[elementType],
        menuElements: [],
        permissions: undefined
    }
};

export const commonDetailsDispatchProps = (dispatch: APThunkDispatch, elementType: ElementTypesEnum): DetailsDispatchProps => {
    return {
        emitEvent: (event: SpitchEvent) => dispatch(emitEvent(event)),
        updateElement: (element, callback) => dispatch(updateGenericElement(element, elementType, callback)),
        pushRoute: (path) => dispatch(push(path)),
        menuElementRemove: () => {
        },
        menuElementUpdate: () => {
        }
    }
};

export const commonStateProps = (state: AppState, elementType: ElementTypesEnum): CommonStateProps => {

    const resolveOrg = (element: any, key: string): string => {
        return state.data.organization.find(o => o._id === element.org)?.name;
    }

    return {
        elements: state.data[elementType],
        table: state.general.tables[elementType],
        permissions: undefined,
        orgTableRowConfig: state.general.user?.is_super && !state.general.organization ? {
            id: 'org',
            type: ConfigurableTableTypes.RowType.text,
            label: i18n.t('common.organization'),
            resolve: resolveOrg,
        } : undefined,
        orgDialogConfigField: state.general.user?.is_super && !state.general.organization ? {
            type: 'select',
            name: "org",
            label: i18n.t('common.organization'),
            persistent: true,
            required: true,
            default: "",
            validate: validateStringField,
            options: (data: any): Array<SelectOption> => state.data.organization.map(o => ({
                value: o._id,
                name: o.name,
                label: o.name
            }))
        } : undefined,
        orgDetailsConfigField: state.general.user?.is_super && !state.general.organization ? {
            key: 'org',
            label: i18n.t('common.organization'),
            type: 'select',
            validate: validateStringField,
            options: (obj, name) => state.data.organization.map(o => ({
                value: o._id,
                name: o.name,
                label: o.name
            })),
            resolve: resolveOrg,
            editable: true
        } : undefined
    }
};

export const commonDispatchProps = (dispatch: APThunkDispatch, elementType: ElementTypesEnum): ConfigurableDispatchProps => {

    return {
        refresh: () => dispatch(loadGenerics(elementType)),
        menuElementAdd: (element) => {
        },
        createElement: (element, callback) => dispatch(updateGenericElement(element, elementType, callback)),
        updateElement: (element, callback) => dispatch(updateGenericElement(element, elementType, callback)),
        deleteElement: (element, callback) => dispatch(deleteGenericElement(element, elementType, callback)),
        updateTableState: (values) => dispatch(setTableState(elementType, values)),
    }
};
