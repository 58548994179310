import {store} from '../store/RootStore';
import {OrganizationModel} from "../model/OrganizationModel";
import {backend} from "./Backend";
import {ElementTypesEnum} from "./CommonTypes";
import {emitEvent} from "../store/actions/GeneralActions";

export const getOrgById = (id: string, cb: (org: OrganizationModel, error: any) => void) => {
    backend.get(ElementTypesEnum.ORGANIZATION, {}, id)
        .then(res => {
            cb(res.data, null);
        })
        .catch(err => {
            store.dispatch(emitEvent({
                modal: false,
                message: 'Error loading org data',
                type: 'error',
                id: ''
            }));
        });
}
