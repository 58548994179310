import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {ConfigurableContent} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableDetails/ConfigurableDetailsTypes";
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {Action, Dispatch} from "redux";
import styleList from "./UserGroupsTab.module.scss";
import {RoleModel} from "../../../../../model/RoleModel";
import ListEditor from "../../../../ListEditor/ListEditor";
import {AppState} from "../../../../../store/ReducerTypes";
import {GroupModel} from "../../../../../model/GroupModel";
import {isUserEditable} from "../../UsersUtils";
import {AuthUser} from "../../../../../store/reducers/GeneralStore";

type OwnProps = {};
type StateProps = {
    groups: GroupModel[],
    user?: AuthUser
}
type DispatchProps = {};
type Props = ConfigurableContent & WithTranslation & OwnProps & StateProps & DispatchProps;
type State = {}

class UserGroupsTab extends React.Component<Props, State> {

    helpPrefix = 'users.details.groups';

    onChange = (items: RoleModel[]) => {
        this.props.onChange('groups', items.map((e: RoleModel) => ({_id: e._id, name: e.name})));
    };

    resolveObjects = (): { _id: string, name: string }[] => {
        const {element, groups} = this.props;
        return element.groups.filter((r: GroupModel) => groups.findIndex(e => e._id === r._id) > -1);
    };

    render() {
        const {t, groups, element, user} = this.props;
        const isEditable = isUserEditable(user);
        return (<div className={styleList.container}>
            <ListEditor
                config={{
                    type: 'select',
                    allItems: groups.filter((e: GroupModel) => e.org === element.org).map(e => ({
                        _id: e._id,
                        name: e.name
                    })),
                    currentItems: this.resolveObjects(),
                    onChange: this.onChange,
                    editable: isEditable(element),
                    messages: {
                        add_button: t(`${this.helpPrefix}.add_button`),
                        input_label: t(`${this.helpPrefix}.input_label`)
                    }
                }}
            />
        </div>)
    };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (state: AppState, ownProps: OwnProps): StateProps => {
    return {
        groups: state.data.group,
        user: state.general.user
    }
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: Dispatch<Action<any>>, ownProps: OwnProps): DispatchProps => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserGroupsTab))
