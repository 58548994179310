import React, {Dispatch} from 'react';
import {ConfigurableVerticalTable, ConfigurableVerticalTableTypes} from "@spitchlingware/ui-reusable-components";
import {ConfigurableContent} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableDetails/ConfigurableDetailsTypes";
import {withTranslation, WithTranslation} from "react-i18next";
import styleList from "./OrganizationPasswordPolicyTab.module.scss";
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import { Action } from 'redux';

type OwnProps = {};
type StateProps = {}
type DispatchProps = {};
type Props = ConfigurableContent & WithTranslation & OwnProps & StateProps & DispatchProps;
type State = {}


class OrganizationPasswordPolicyTab extends React.Component<Props, State> {

    helpPrefix = 'organizations';

    handleChange = (name: string, value: any) => {
        this.props.onChange(name, value);
    };

    detailsTableConfig = (): Array<ConfigurableVerticalTableTypes.ConfigurableVerticalTableConfigType> => {
        const fnames = ['min_length', 'max_length', 'lowercase', 'uppercase', 'numeric', 'symbol'];
        const {t} = this.props;
        return fnames.map(name => ({
            key: `settings.password_${name}`,
            label: t(`${this.helpPrefix}.details.password_policy_table.rows.${name}`),
            type: 'text',
            regexpMask: '0-9',
            editable: true
        }))
    };

    render() {
        const {element} = this.props;
        const {t} = this.props;
        const detailsConfig = this.detailsTableConfig();
        return (<div className={styleList.container}>
            <ConfigurableVerticalTable name={t(`${this.helpPrefix}.details.password_policy_table.title`)}
                                       config={detailsConfig}
                                       onChange={this.handleChange}
                                       data={element}
                                       permissions={this.props.permissions}
            />
        </div>)
    };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (state: any, ownProps: OwnProps): StateProps => {
    return {}

};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: Dispatch<Action<any>>, ownProps: OwnProps): DispatchProps => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrganizationPasswordPolicyTab))
