import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ConnectedRouter as Router} from 'connected-react-router';
import {browserHistory, store} from "./store/RootStore";
import {Provider} from 'react-redux';
import {getTheme} from "./material.theme";
import {MuiThemeProvider} from "@material-ui/core";
import {SnackbarProvider} from "notistack";
import {ActionTypesEnum} from "./utils/CommonTypes";
import {backend} from "./utils/Backend";
import {getBrandConfig} from "@spitchlingware/ui-reusable-components/dist/components/Utils/BrandingUtils";

backend.get(ActionTypesEnum.APP_SETTINGS, {}).then(res => {
    const settings = getBrandConfig(res.data.brand);
    ReactDOM.render(<Provider store={store}>
            <SnackbarProvider maxSnack={7} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }} preventDuplicate>
                <Router history={browserHistory}>
                    <MuiThemeProvider theme={getTheme(settings.palette())}>
                        <App appSettings={settings}/>
                    </MuiThemeProvider>
                </Router>
            </SnackbarProvider>
        </Provider>, document.getElementById('root'))
}).catch(e => {
    console.log(e);
    ReactDOM.render(<div>Error</div>, document.getElementById('root'));
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
