import {BaseSchema} from "./BaseSchema";

export type GroupedFeatures = Record<string, { feature_id: string, value: any }>

export interface GroupModel extends BaseSchema {
    name: string
    description: string | undefined
    roles: {
        _id: string,
        name: string
    }[]
    features: GroupedFeatures
}

export const applyGroupDefaults = (item: any): void => {
    item.features = {}
};
