import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {FeatureTypeEnum} from "../../utils/CommonTypes";
import {Button, Checkbox, FormControlLabel, MenuItem, TextField} from "@material-ui/core";
import ArrowIcon from "@material-ui/icons/ArrowRightAlt";
import classNames from "classnames";

type Option = { value: string, title: string };

type OwnProps = {
    value: any,
    type: FeatureTypeEnum
    onChange: (value: any) => void,
    // enable reset button
    enableReset: boolean,
    // the value is a default value, highlight inputs accordingly
    isDefault?: boolean,
    options?: Option[],
    labels: {
        input_label: string,
        default_value_title?: string,
        buttons?: {
            reset: string
        }
    }
}

type Props = OwnProps

const useStyles = makeStyles({
        rangeContainer: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center"
        },
        select: {
            minWidth: "300px"
        },
        itemContainer: {
            display: "flex"
        },
        defaultValue: {
            backgroundColor: "rgba(204,204,204,0.53)"
        }
    }
);

const TypedValueEditor: React.FC<Props> = (props) => {
    const {type, value, options, onChange, labels, enableReset, isDefault} = props;
    const classes = useStyles();
    const [rangeStart, setRangeStart] = useState<number>(0);
    const [rangeEnd, setRangeEnd] = useState<number>(0);

    useEffect(() => {
        const vv = (value + '' || '').split(':').map((e: string) => parseInt(e));
        setRangeStart(vv[0] || 0);
        setRangeEnd(vv[1] || 0);
    }, [type, value]);

    const getTitle = () => {
        return isDefault ? labels.default_value_title : undefined;
    }

    const getEditor = () => {
        switch (type) {
            case FeatureTypeEnum.boolean:
                const check = <Checkbox
                    title={getTitle()}
                    checked={value}
                    className={classNames({[classes.defaultValue]: isDefault})}
                    onChange={event => onChange(event.target.checked)}
                    value={value}
                    color="primary"
                />;
                if (labels.input_label) {
                    return <FormControlLabel
                        label={labels.input_label}
                        labelPlacement="start"
                        control={check}/>
                } else {
                    return check;
                }
            case FeatureTypeEnum.number:
                return <TextField
                    label={labels.input_label}
                    title={getTitle()}
                    className={classNames({[classes.defaultValue]: isDefault})}
                    variant={"outlined"}
                    type="number"
                    fullWidth
                    multiline
                    value={parseInt(value) || 0}
                    onChange={event => onChange(event.target.value)}
                    margin="dense"
                />
            case FeatureTypeEnum.string:
                return <TextField
                    label={labels.input_label}
                    title={getTitle()}
                    className={classNames({[classes.defaultValue]: isDefault})}
                    variant={"outlined"}
                    type="text"
                    fullWidth
                    multiline
                    value={value}
                    onChange={event => onChange(event.target.value)}
                    margin="dense"
                />
            case FeatureTypeEnum.list:
                const values: Option[] = options || [];
                const currentOption = values.find(e => e.value === value) || values[0];
                const currentValue = currentOption ? currentOption.value : '';
                return <TextField
                    label={labels.input_label}
                    title={getTitle()}
                    className={classNames(classes.select, {[classes.defaultValue]: isDefault})}
                    select
                    variant={"outlined"}
                    fullWidth
                    value={currentValue}
                    onChange={event => onChange(event.target.value)}
                    margin="dense"
                >
                    {values.map((option: Option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.title}
                        </MenuItem>
                    ))}
                </TextField>
            case FeatureTypeEnum.range:
                return <div className={classes.rangeContainer}>
                    <TextField
                        label={labels.input_label}
                        title={getTitle()}
                        className={classNames({[classes.defaultValue]: isDefault})}
                        type="number"
                        variant={"outlined"}
                        fullWidth
                        value={rangeStart}
                        onChange={event => {
                            setRangeStart(parseInt(event.target.value));
                            onChange(`${event.target.value}:${rangeEnd}`)
                        }}
                        margin="dense"
                    />
                    <ArrowIcon/>
                    <TextField
                        type="number"
                        title={getTitle()}
                        className={classNames({[classes.defaultValue]: isDefault})}
                        variant={"outlined"}
                        fullWidth
                        value={rangeEnd}
                        onChange={event => {
                            setRangeEnd(parseInt(event.target.value));
                            onChange(`${rangeStart}:${event.target.value}`)
                        }}
                        margin="dense"
                    />
                </div>
            default:
                return null;
        }
    }
    const editor = getEditor();
    if (editor) {
        return (
            <div className={classes.itemContainer}>
                <span style={{flex: 1}}>{editor}</span>
                {
                    enableReset && !isDefault &&
                    <Button color={"secondary"} onClick={() => onChange(undefined)}>
                        {labels?.buttons?.reset || 'reset'}
                    </Button>
                }
            </div>
        )
    }
    return null;
};

export default TypedValueEditor;

