import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router-dom'
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {APThunkDispatch} from "../../../../store/ReducerTypes";
import {UserModel} from "../../../../model/UserModel";
import {ConfigurableDetailsLabels} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableDetails/ConfigurableDetailsTypes";
import {ConfigurableDetails, ConfigurableDetailsTypes} from "@spitchlingware/ui-reusable-components";
import {ElementTypesEnum} from "../../../../utils/CommonTypes";
import {commonDetailsDispatchProps, commonDetailsStateProps} from "../../../../utils/ReducerFunctions";
import {WithTranslation, withTranslation} from "react-i18next";
import {
    DetailsDispatchProps,
    DetailsStateProps
} from "@spitchlingware/ui-reusable-components/dist/components/Utils/ConfigurablesHelper";
import UserDetailsTab from "./Tabs/UserDetailsTab";
import UserGroupsTab from "./Tabs/UserGroupsTab";
import {isUserEditable} from "../UsersUtils";
import {AuthUser} from "../../../../store/reducers/GeneralStore";

type RouteProps = {
    id: string
}

type OwnProps = {};
type StateProps = DetailsStateProps & { user: AuthUser };
type DispatchProps = DetailsDispatchProps;

type Props = OwnProps & StateProps & DispatchProps & WithTranslation & RouteComponentProps<RouteProps>;

class UsersDetails extends Component<Props> {

    rolePrefix = 'users.details';

    constructor(props: Props) {
        super(props);
        const element = this.getElement();
        if (!element) this.props.history.push(`/${ElementTypesEnum.USER}`);
    }

    prepareTabs = (): Array<ConfigurableDetailsTypes.ConfigurableTab> => {
        const {t} = this.props;
        return [
            {
                value: 0,
                label: t(`${this.rolePrefix}.tabs.general`),
                header: t(`${this.rolePrefix}.tabs.general`),
                content: (): React.ComponentType<ConfigurableDetailsTypes.ConfigurableContent> => {
                    return UserDetailsTab;
                }
            },
            {
                value: 1,
                label: t(`${this.rolePrefix}.tabs.groups`),
                header: t(`${this.rolePrefix}.tabs.groups`),
                content: (): React.ComponentType<ConfigurableDetailsTypes.ConfigurableContent> => {
                    return UserGroupsTab;
                }
            }
        ];
    };

    labels = (): ConfigurableDetailsLabels => {
        const {t} = this.props;
        return {
            cancelButton: t(`${this.rolePrefix}.buttons.cancel`),
            saveButton: t(`${this.rolePrefix}.buttons.save`),
        }
    };

    onSave = (element: any): void => {
        this.props.updateElement(element, (saved) => {
            this.props.history.push(`/${ElementTypesEnum.USER}`);
        })
    };

    onCancel = (): void => {
        this.props.history.push(`/${ElementTypesEnum.USER}`);
    };

    getElement = (): UserModel => {
        const {id} = this.props.match.params;
        const {elements} = this.props;
        return elements.find(e => e._id === id);
    };

    render() {
        const {user} = this.props;
        const isEditable = isUserEditable(user)(this.getElement());
        return (
            <div>
                <ConfigurableDetails tabs={this.prepareTabs()}
                                     elementType={ElementTypesEnum.USER}
                                     data={undefined}
                                     labels={this.labels()}
                                     element={this.getElement()}
                                     onCancel={this.onCancel}
                                     onSave={isEditable ? this.onSave : undefined}/>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (state: any, ownProps: OwnProps): StateProps => {
    return {
        ...commonDetailsStateProps(state, ElementTypesEnum.USER),
        user: state.general.user
    }
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: APThunkDispatch, ownProps: OwnProps): DispatchProps => {
    return commonDetailsDispatchProps(dispatch, ElementTypesEnum.USER)
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UsersDetails));
