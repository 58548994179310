import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core";
import {AppRoute} from "../../utils/CommonTypes";
import NotFound from "../RoutedComponents/NotFound/NotFound";

type OwnProps = {
    routes: Array<AppRoute>,
    orgId: string
}
type Props = OwnProps & WithStyles;

const Container: React.FC<Props> = (props) => {

    const getRoutePrefix = () => {
        if (props.orgId) {
            return '/' + props.orgId;
        }
        return '';
    }

    const currentRoutes = props.routes.map((route, index) => {
        return <Route key={index} exact={route.exact} path={getRoutePrefix() + route.path} component={route.component}/>
    });

    const {classes} = props;

    return (
        <div className={classes.root}>
            <Switch>
                {currentRoutes}
                <Route component={NotFound}/>
            </Switch>
        </div>
    );
};

const mapStylesToProps = (theme: Theme) => {
    return createStyles({
        root: {
            width: '100%',
            backgroundColor: '#FAFAFA'
        }
    })
}

export default withStyles(mapStylesToProps)(Container)
