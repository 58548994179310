import {FieldUpdateValue, GeneralAction, TableUpdate, TableUpdateValue} from "../ReducerTypes";
import {GeneralActionType} from "../actions/GeneralActionTypes";
import {DataTable} from "@spitchlingware/ui-reusable-components/dist/components/Utils/ConfigurablesHelper";
import {ElementTypesEnum} from "../../utils/CommonTypes";

export type AuthUser = {
    org_id: string
    user_id: string
    roles: string[]
    is_admin: boolean
    is_super: boolean
    username: string
    email: string
}

export type GeneralStoreState = {
    user?: AuthUser,
    organization: string
    tables: Record<ElementTypesEnum, DataTable<any>>
}

const initTable = (): DataTable => {
    return {
        selected: undefined,
        page: 0,
        rowsPerPage: 10,
        searchField: '',
        expanded: undefined
    }
};

const initState = () => {
    const tables = Object.keys(ElementTypesEnum)
        .map((key: string) => ElementTypesEnum[key as keyof typeof ElementTypesEnum])
        .reduce((acc: Record<ElementTypesEnum, DataTable>, key: ElementTypesEnum) => {
            acc[key] = initTable();
            return acc;
        }, {} as Record<ElementTypesEnum, DataTable>);

    return {
        tables,
        organization: ""
    }
}


const setTableState = (state: GeneralStoreState, action: GeneralAction<TableUpdate>): GeneralStoreState => {
    const {table, values} = action.payload;
    const updated_table: DataTable = {...state.tables[table]};
    values.forEach((v: TableUpdateValue) => {
        // @ts-ignore
        updated_table[v.name] = v.value
    });

    return {
        ...state,
        tables: {
            ...state.tables,
            [table]: updated_table
        }
    }
};

const setStateField = (state: GeneralStoreState, action: GeneralAction<FieldUpdateValue>): GeneralStoreState => {
    return {
        ...state,
        [action.payload.name]: action.payload.value
    }
}

export const generalReducer = (state: GeneralStoreState = initState(), action: GeneralAction): GeneralStoreState => {
    switch (action.type) {
        case GeneralActionType.SET_AUTH_USER:
            return {
                ...state,
                user: action.payload
            }
        case GeneralActionType.SET_TABLE_STATE:
            return setTableState(state, action)
        case GeneralActionType.SET_STATE_FIELD:
            return setStateField(state, action);
        default:
            return state;
    }
};
