import React, {FunctionComponent, useEffect, useState} from "react";
import {Button, createStyles, Paper, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {ConfigurableVerticalTable, ConfigurableVerticalTableTypes} from "@spitchlingware/ui-reusable-components";
import {backend} from "../../../utils/Backend";
import {ElementTypesEnum} from "../../../utils/CommonTypes";
import {connect, useSelector} from "react-redux";
import {AppState, APThunkDispatch} from "../../../store/ReducerTypes";
import {validateString, validateStringField} from "../../../utils/TableUtils";
import {updateGenericElement} from "../../../store/actions/DataActions";

type UserProfileProps = {
    dispatch: APThunkDispatch
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: 700,
            '& .MuiTextField-root': {
                margin: theme.spacing(1)
            },
            margin: "auto",
            marginTop: "1em"
        },
        header: {
            margin: theme.spacing(1),
            fontSize: "1.3em"
        },
        buttons: {
            display: "flex",
            margin: theme.spacing(1),
            alignItems: "center"
        }
    }),
);

const UserProfile: FunctionComponent<UserProfileProps> = ({dispatch}) => {
    const helpPrefix = "user_profile";
    const classes = useStyles();
    const {t} = useTranslation();
    const user = useSelector((state: AppState) => state.general.user);
    const [profile, setProfile] = useState<any>({});

    useEffect(() => {
        if (user) {
            backend.get(ElementTypesEnum.USER, {_id: user.user_id})
                .then(value => {
                    if (value?.data.length > 0) {
                        setProfile(value.data[0]);
                    }
                })
                .catch(reason => {
                    console.log(reason);
                })
        }
    }, [user]);

    const handleChange = (name: string, value: any) => {
        setProfile({
            ...profile,
            [name]: value
        })
    };

    const handleSave = () => {
        dispatch(updateGenericElement(profile, ElementTypesEnum.USER, element => {
            console.log("Updated");
        }));
    }

    const detailsTableConfig = (): Array<ConfigurableVerticalTableTypes.ConfigurableVerticalTableConfigType> => {
        return [
            {
                key: 'firstName',
                label: t(`${helpPrefix}.fields.firstname`),
                type: 'text',
                validate: validateStringField,
                editable: true
            },
            {
                key: 'lastName',
                label: t(`${helpPrefix}.fields.lastname`),
                type: 'text',
                validate: validateStringField,
                editable: true
            },
            {
                key: 'email',
                label: t(`${helpPrefix}.fields.email`),
                type: 'text',
                validate: validateStringField,
                editable: true
            }
        ];
    };

    const isValid = () => {
        return validateString(profile.firstName) && validateString(profile.lastName) && validateString(profile.email);
    }

    return <Paper className={classes.root}>
        <ConfigurableVerticalTable name={t(`${helpPrefix}.title`)}
                                   config={detailsTableConfig()}
                                   onChange={handleChange}
                                   data={profile}
                                   permissions={undefined}/>
        <div className={classes.buttons}>
            <Button variant="contained" color="primary" style={{margin: "auto"}}
                    disabled={!isValid()}
                    onClick={handleSave}>
                {t(`${helpPrefix}.buttons.save`)}
            </Button>
        </div>
    </Paper>;
}

export default connect()(UserProfile);

