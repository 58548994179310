import React from 'react';
import {RouterState} from 'connected-react-router'
import {createStyles, CssBaseline, Theme, WithStyles, withStyles} from "@material-ui/core";
import {SideDrawer, TopToolBar} from "@spitchlingware/ui-reusable-components/dist/components/General";
import {getRoutes} from "./utils/AppRoutes";
import {RouteComponentProps, withRouter} from "react-router-dom";
import Container from "./components/Container/Container";
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {AppState, APThunkDispatch, NotifierStateProps} from "./store/ReducerTypes";
import i18n from "./i18n";
import {WithTranslation, withTranslation} from "react-i18next";
import {SessionService} from "./services/SessionService";
import {backend, doLogout} from "./utils/Backend";
import {loadAllElements} from "./store/actions/DataActions";
import {AuthUser} from "./store/reducers/GeneralStore";
import {setAuthUser} from "./store/actions/GeneralActions";
import {AppRoute} from "./utils/CommonTypes";
import OrgIcon from '@material-ui/icons/Business';
import {OrganizationModel} from "./model/OrganizationModel";
import OrgSelectionDialog from "./components/OrgSelectionDialog/OrgSelectionDialog";
import Notifier from "./components/Notifier/Notifier";
import {notiferStateProps} from "./utils/NotifierHelpers";
import {AppSettings} from "@spitchlingware/ui-reusable-components/dist/components/Utils/BrandingUtils";
import Logo from "@spitchlingware/ui-reusable-components/dist/components/General/Logo/Logo";
import {ToolBarItem} from "@spitchlingware/ui-reusable-components/dist/components/General/TopToolBar/TopToolBar";
import {MenuGroup} from "@spitchlingware/ui-reusable-components/dist/components/General/SideDrawer/SideDrawerTypes";

type StateProps = {
    router: RouterState,
    user?: AuthUser,
    currentOrg: string,
    organizations: OrganizationModel[]
}

type OwnProps = {
    appSettings: AppSettings
}

type DispatchProps = {
    loadAllElements: (org: string) => void,
    setAuthUser: (user: AuthUser) => void,
} & NotifierStateProps;

type Props = StateProps & WithStyles & RouteComponentProps<{}> & WithTranslation & DispatchProps & OwnProps;

type State = {
    loggedIn: boolean,
    language: string,
    routes: AppRoute[] | undefined,
    dialog: string | undefined
}

class App extends React.Component<Props, State> {

    state = {
        loggedIn: true,
        language: 'en-us',
        routes: undefined,
        dialog: undefined
    };

    restoreState = (orgId: string = '') => {
        const {user} = this.props;
        if (!user) {
            // we need to refresh token once before start loading data
            backend.user_data().then((res) => {
                const parsed = res.data as AuthUser;
                const correctOrgId = parsed.is_super ? orgId : parsed.org_id;
                this.props.setAuthUser(parsed);
                this.props.loadAllElements(correctOrgId);
            });
        }
        this.setState({
            routes: getRoutes(user, this.getCurrentOrgId())
        });
    }

    pushHistory = (path: string) => {
        this.props.history.push(path);
    }

    /**
     * Extract the org id from the path or the browser storage
     * Load data from the server
     */
    componentDidMount() {
        const savedOrg = this.getProjectIdFromLocation() || SessionService.org();
        this.restoreState(savedOrg);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.currentOrg !== this.props.currentOrg || prevProps.user !== this.props.user) {
            this.restoreState(this.props.currentOrg);
        }
    }

    /**
     * Get the org id from the url pathname, the org is the first path element
     */
    getProjectIdFromLocation = () => {
        const {router} = this.props;
        const pp = router.location.pathname.split('/');
        if (pp.length > 1) {
            return pp[1];
        }
        return '';
    }

    getCurrentOrgId = () => {
        return this.props.currentOrg;
    }

    getVisibleRoutes = (): AppRoute[] => {
        return this.state.routes || [];
    }

    prepareSideDrawerElements = (): Array<MenuGroup> => {
        const {location, t} = this.props;
        const menuGroup: MenuGroup = {
            title: "Configuration",
            id: "all_elements",
            elements: this.getVisibleRoutes().filter(rt => rt.menu).map((rt, index) => {
                let activeRoute = location.pathname !== '/' && (location.pathname.endsWith(rt.path) || location.pathname.indexOf(`${rt.path}/`) > -1)
                const icon = rt.icon && <rt.icon/>;
                return {
                    menu: true,
                    label: t(`side_drawer.${rt.name}`),
                    errorMessage: 'Error',
                    active: activeRoute,
                    icon: icon,
                    children: undefined,
                    onClick: () => {
                        this.pushHistory(rt.path);
                    }
                }
            })
        }
        return [menuGroup];
    };

    logoff = async () => {
        doLogout();
    }

    closeDialog = () => {
        this.setState({
            dialog: undefined
        });
    }

    openDialog = (name: string) => {
        this.setState({
            dialog: name
        });
    }

    handleSelectOrg = (id: string) => {
        this.setState({
            dialog: undefined
        });
        this.props.loadAllElements(id);
        this.props.history.push('/');
    }

    renderToolbar = () => {
        const {router, t, user, organizations} = this.props;
        const {dialog} = this.state;
        const {pathname} = router.location;
        const dialogs: Array<ToolBarItem> = [
            {
                type: "menu",
                disabled: true,
                label: user?.username || "Unknown user",
                dialog: undefined,
                icon: undefined,
                onClick: () => {
                }
            },
            {
                type: "menu",
                disabled: true,
                label: user?.email || "Unknown email",
                dialog: undefined,
                icon: undefined,
                onClick: () => {
                }
            },
        ];
        const org = organizations.find(e => e._id === this.getCurrentOrgId());

        if (user && user.is_super) {
            dialogs.push({
                type: "button",
                disabled: false,
                label: org ? org.name : t("toolbar.select_org.title"),
                dialog: <OrgSelectionDialog
                    open={dialog === "org"}
                    onClose={this.closeDialog}
                    onSelect={this.handleSelectOrg}
                    items={organizations}
                    selected={this.getCurrentOrgId()}
                />,
                onClick: () => this.openDialog("org"),
                icon: OrgIcon
            });
        }

        return <TopToolBar
            open={true}
            toggle={() => {
            }}
            logoff={this.logoff}
            logo={<Logo appSettings={this.props.appSettings}/>}
            applicationName={t('application_name')}
            pushRoute={this.pushHistory}
            getCopyright={this.props.appSettings.copyright}
            t={t}
            pathname={pathname}
            dialogs={dialogs}>
        </TopToolBar>
    }

    handleChangeLanguage = async (lang: string) => {
        await i18n.changeLanguage(lang, (err: any) => {
            if (err) console.warn(`i18n error: ${err}`)
        })
        this.setState({
            language: lang
        });
    }

    render() {
        const {classes} = this.props;
        const drawerWidth = 240
        return (
            <div className={classes.root}>
                <Notifier/>
                <React.Fragment>
                    <CssBaseline/>
                    {this.renderToolbar()}
                    <SideDrawer elements={this.prepareSideDrawerElements()} width={drawerWidth}/>
                    <div className={classes.content}
                         style={{left: drawerWidth, width: `calc(100% - ${drawerWidth}px)`}}>
                        <Container routes={this.getVisibleRoutes()} orgId={this.getCurrentOrgId()}/>
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

const mapStylesToProps = (theme: Theme) => {
    const marginTop = 64;
    return createStyles({
        root: {
            display: 'flex',
            height: '100vh',
        },
        content: {
            width: '100%',
            marginTop: marginTop,
            boxSizing: 'border-box',
            position: 'relative',
        }
    })
}
const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state: AppState, ownProps: OwnProps): StateProps => {
    const {router, general, data} = state;
    return {
        router,
        user: general.user,
        organizations: data.organization,
        currentOrg: general.organization
    }
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: APThunkDispatch, ownProps: OwnProps): DispatchProps => {
    return {
        loadAllElements: (org: string) => dispatch(loadAllElements(org)),
        setAuthUser: (user: AuthUser) => dispatch(setAuthUser(user)),
        ...notiferStateProps(dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(mapStylesToProps)(App))))
