import {APThunkDispatch, NotifierStateProps} from "../store/ReducerTypes";
import {SnackbarKey} from "notistack";
import {NotifierClose} from "../components/Notifier/NotifierClose";
import {enqueueSnackbar} from "../store/actions/NotiferActions";
import React from "react";

export type NotifierType = "default" | "error" | "success" | "warning" | "info" | undefined;


const duration = (type: NotifierType) => {
    if (type === 'error') {
        return 5000;
    }
    return 2000;
}
const action: any = (key: SnackbarKey) => {
    return (<NotifierClose msgKey={key}/>);
}

export const notify = (dispatch: any, message: string, type: NotifierType) => {
    dispatch(enqueueSnackbar({
        message: message,
        key: new Date().getTime() + Math.random(),
        options: {
            variant: type,
            action: action,
            preventDuplicate: true,
            autoHideDuration: duration(type)
        }
    }))
}

export const notiferStateProps = (dispatch: APThunkDispatch): NotifierStateProps => {
    return {
        notify: (message, type = 'info') => notify(dispatch, message, type)
    }
}