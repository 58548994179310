import axios from "axios";
import {SessionService} from "../services/SessionService";
import {get_process_env} from "./CommonFunctions";
import {ActionTypesEnum, ElementTypesEnum} from "./CommonTypes";

export const HTTP_TIMEOUT = 10000;

axios.defaults.withCredentials = true;

export const backendEndpoints: Record<ElementTypesEnum | ActionTypesEnum, string> = {
    user: "/api/user",
    group: "/api/group",
    organization: "/api/organization",
    role: "/api/role",
    login: "/api/auth/login",
    logout: "/api/auth/logout",
    refresh_token: "/api/auth/refresh_token",
    change_password: "/api/user/change_password",
    user_profile: "",
    app_settings: "/api/app/settings",
    feature: "/api/feature",
    feature_group: "/api/feature_group",
    user_data: "/api/auth/user_data"
};

export const doLogout = () => {
    SessionService.clear();
    window.location.href = get_process_env("REACT_APP_API_BACKEND_URL") + backendEndpoints.logout;
}

type backendType = {
    login: (email: string, password: string) => Promise<any>,
    change_password: (current: string, password: string) => Promise<any>,
    get: (elementType: ElementTypesEnum | ActionTypesEnum, query: Record<string, any>, id?: string | undefined) => Promise<any>
    post: (elementType: ElementTypesEnum | ActionTypesEnum, body: any) => Promise<any>
    put: (elementType: ElementTypesEnum | ActionTypesEnum, body: any) => Promise<any>
    delete: (elementType: ElementTypesEnum | ActionTypesEnum, body: any) => Promise<any>
    refresh_token: () => Promise<any>
    user_data: () => Promise<any>
}

const axisBackend = axios.create({
    baseURL: get_process_env("REACT_APP_API_BACKEND_URL"),
    timeout: HTTP_TIMEOUT
});

/**
 * In case of the request is unauthorized, redirect to the backend, the backend in its turn will redirect to the auth2 server
 */
axisBackend.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        doLogout();
    }
    return Promise.reject(error)
})

export const backend: backendType = {
    login: async (email: string, password: string) => {
        return await axisBackend.post(backendEndpoints[ActionTypesEnum.LOGIN], {email: email, password: password});
    },
    change_password: async (current: string, password: string) => {
        return await axisBackend.post(backendEndpoints[ActionTypesEnum.CHANGE_PASSWORD], {
            current: current,
            password: password
        });
    },
    refresh_token: async () => {
        return await axisBackend.get(backendEndpoints[ActionTypesEnum.REFRESH_TOKEN], {});
    },
    user_data: async () => {
        return await axisBackend.get(backendEndpoints[ActionTypesEnum.USER_DATA], {});
    },
    get: async (elementType: ElementTypesEnum | ActionTypesEnum, query: Record<string, any> = {}, id: string | undefined = undefined) => {
        if (id) {
            return await axisBackend.get(`${backendEndpoints[elementType]}/${id}`, {params: {params: query}});
        } else {
            return await axisBackend.get(backendEndpoints[elementType], {params: {params: query}});
        }
    },
    post: async (elementType: ElementTypesEnum | ActionTypesEnum, body: any) => {
        return await axisBackend.post(backendEndpoints[elementType], body);
    },
    put: async (elementType: ElementTypesEnum | ActionTypesEnum, body: any) => {
        return await axisBackend.put(backendEndpoints[elementType], body);
    },
    delete: async (elementType: ElementTypesEnum | ActionTypesEnum, body: any) => {
        return await axisBackend.delete(`${backendEndpoints[elementType]}/${body}`);
    }
}
