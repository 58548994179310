import i18n from 'i18next';
import XHR from "i18next-xhr-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEng from "./assets/i18n/en-us.yaml";
import translationRus from "./assets/i18n/ru-ru.yaml";
import {initReactI18next} from "react-i18next";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(XHR)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // // pass the i18n instance to react-i18next
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false, // process.env.NODE_ENV === 'development',
        fallbackLng: 'en', // use en if detected lng is not available
        keySeparator: ".", // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        resources: {
            en: {
                translations: translationEng
            },
            ru: {
                translations: translationRus
            }
        },
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",
        react: {
            useSuspense: false
        }
    });

export default i18n;