import {applyMiddleware, combineReducers, compose, createStore, Store} from 'redux';
import {dataReducer} from './reducers/DataStore'
import thunk from 'redux-thunk';
import {createBrowserHistory} from "history";
import {AppState, APThunkDispatch, SummaryAction} from "./ReducerTypes";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {generalReducer} from "./reducers/GeneralStore";
import {notiferReduser} from "./reducers/NotiferStore";

export const browserHistory = createBrowserHistory();

const rootReducer = combineReducers({
    data: dataReducer,
    general: generalReducer,
    notifier: notiferReduser,
    router: connectRouter(browserHistory)
});

// @ts-ignore
const composeEnhancers = process.env.NODE_ENV === 'development' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;

const thunkMiddleware = applyMiddleware<APThunkDispatch, AppState>(thunk);

export const store: Store<AppState, SummaryAction> = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(routerMiddleware(browserHistory), thunk.withExtraArgument({})),
        thunkMiddleware
    )
);

/**
 * Change the history push
 * Prepend the path with the current org id
 */
const origPush = browserHistory.push;
browserHistory.push = (path: any) => {
    const orgId = store.getState().general.organization || '';
    if (orgId && !path.startsWith(`/${orgId}`)) {
        if (path === '/') {
            origPush.bind(browserHistory)(`/${orgId}`);
        } else {
            origPush.bind(browserHistory)(`/${orgId}${path}`);
        }
    } else {
        origPush.bind(browserHistory)(path);
    }
};
