import {commonDialogLabels} from "@spitchlingware/ui-reusable-components/dist/components/Utils/ConfigurablesHelper";
import {validateStringField} from "../../../utils/TableUtils";
import {FormDialog} from "@spitchlingware/ui-reusable-components/dist/components/Commons/Dialogs";
import React from "react";
import {FeatureGroupModel} from "../../../model/FeatureGroupModel";

type Props = {
    t: (id: string) => string,
    open: boolean,
    data: FeatureGroupModel,
    cancel: () => void,
    confirm: (data: any) => void,
    validateGroupName: (element: any, fieldName: string) => boolean
}

export default function GroupEditDialog(props: Props) {
    return <FormDialog
        open={props.open}
        data={props.data}
        edit={!!props.data._id}
        cancel={props.cancel}
        confirm={props.confirm}
        config={{
            name: props.t(`form_dialog.title`),
            labels: commonDialogLabels(),
            fields: [
                {
                    type: 'text',
                    name: "title",
                    label: props.t(`form_dialog.fields.title`),
                    required: true,
                    maxLength: 100,
                    validate: validateStringField,
                    default: ''
                },
                {
                    type: 'text',
                    name: "group_name",
                    label: props.t(`form_dialog.fields.name`),
                    required: true,
                    maxLength: 100,
                    validate: (obj, fieldName) => props.validateGroupName(obj, fieldName),
                    default: ''
                }
            ]
        }}
    />
}
