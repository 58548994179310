import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {AppState, APThunkDispatch, CommonStateProps} from "../../../store/ReducerTypes";
import {validateUniqueField} from "../../../utils/TableUtils";
import {
    ConfigurableElementList,
    ConfigurableElementListTypes,
    ConfigurableTableTypes,
    Dialogs
} from "@spitchlingware/ui-reusable-components";
import {
    commonDeleteDialogLabels,
    commonDialogLabels,
    commonPaginatorLabels,
    commonPagingFeatures,
    commonToolbarLabels,
    ConfigurableDispatchProps
} from "@spitchlingware/ui-reusable-components/dist/components/Utils/ConfigurablesHelper";
import {withTranslation, WithTranslation} from "react-i18next";
import {ElementTypesEnum} from "../../../utils/CommonTypes";
import {commonDispatchProps, commonStateProps} from "../../../utils/ReducerFunctions";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";

type OwnProps = {
    t: (value: string) => string
};
type StateProps = CommonStateProps & {}
type DispatchProps = ConfigurableDispatchProps & {};
type Props = OwnProps
    & StateProps
    & DispatchProps
    & WithStyles
    & WithTranslation
    & RouteComponentProps;
type State = {};

class Roles extends Component<Props, State> {

    i18nPrefix = "roles"

    prepareDialogConfig = (): Dialogs.DialogTypes.DialogConfig => {
        const {t, elements, orgDialogConfigField} = this.props;
        const org = orgDialogConfigField ? [orgDialogConfigField] : [];
        return {
            name: t(`${this.i18nPrefix}.form_dialog.title`),
            labels: commonDialogLabels(),
            fields: [
                ...org,
                {
                    type: 'text',
                    name: "name",
                    label: t(`${this.i18nPrefix}.form_dialog.name`),
                    required: true,
                    maxLength: 100,
                    validate: (obj, fieldName) => validateUniqueField(obj, fieldName, elements),
                    default: ''
                },
                {
                    type: 'text',
                    name: "uid",
                    label: t(`${this.i18nPrefix}.form_dialog.uid`),
                    required: true,
                    maxLength: 100,
                    validate: (obj, fieldName) => validateUniqueField(obj, fieldName, elements),
                    default: ''
                }, {
                    type: 'text',
                    name: "description",
                    label: t(`${this.i18nPrefix}.form_dialog.description`),
                    required: true,
                    maxLength: 100,
                    default: ''
                },
            ]
        };
    };


    prepareTableConfig = (): ConfigurableTableTypes.TableConfig => {
        const {t, orgTableRowConfig} = this.props;
        const org = orgTableRowConfig ? [orgTableRowConfig] : [];
        return {
            name: t(`${this.i18nPrefix}.table_config.title`),
            selectedField: 'name',
            searchFields: ['name'],
            rows: [
                ...org,
                {
                    id: 'name',
                    type: ConfigurableTableTypes.RowType.text,
                    label: t(`${this.i18nPrefix}.table_config.rows.name`),
                },
                {
                    id: 'uid',
                    type: ConfigurableTableTypes.RowType.text,
                    label: t(`${this.i18nPrefix}.table_config.rows.uid`),
                },
                {
                    caption: t(`${this.i18nPrefix}.table_config.rows.details`),
                    label: t(`${this.i18nPrefix}.table_config.rows.details`),
                    type: ConfigurableTableTypes.RowType.button,
                    action: (element: any, event: any) => {
                        this.props.history.push(`/${ElementTypesEnum.ROLE}/${element._id}`);
                    }
                }
            ]
        };
    };

    createElement = (item: any) => {
        this.props.createElement(item, (saved) => {
            this.props.history.push(`/${ElementTypesEnum.ROLE}/${saved._id}`);
        });
    };

    refresh = () => {
        this.props.refresh();
    };

    render() {
        const {elements, updateElement, deleteElement, t, classes} = this.props;

        const dialogParameters: ConfigurableElementListTypes.DialogParameters = {
            config: this.prepareDialogConfig,
            onCreate: this.createElement,
            onUpdate: updateElement,
            onDelete: deleteElement,
            onRefresh: this.refresh,
            deleteLabels: commonDeleteDialogLabels(t),
            dialogLabels: commonDialogLabels(t),
        };

        const tableParameters: ConfigurableElementListTypes.TableParameters = {
            config: this.prepareTableConfig,
            data: elements,

            rowsPerPage: 10,
            searchable: true,
            selectable: true,
            expandable: false,
            paginatorLabels: commonPaginatorLabels(t),
            toolbarLabels: commonToolbarLabels(t),
            pagingFeatures: commonPagingFeatures(),
            //state: table,
            //changeState: updateTableState,

            externalPaging: false,
            //extOnPageChange: (p1: any, p2: number) => {},
            //extPageNumber: 0,
            //extTotalRows: 0,
        };

        return (
            <div className={classes.root}>
                <ConfigurableElementList
                    dialogParameters={dialogParameters}
                    tableParameters={tableParameters}/>
            </div>
        );
    }
}

const elementType = ElementTypesEnum.ROLE;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state: AppState, ownProps: OwnProps): StateProps => {
    return commonStateProps(state, elementType)
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: APThunkDispatch, ownProps: OwnProps): DispatchProps => {
    return commonDispatchProps(dispatch, elementType)
};


const mapStylesToProps = (theme: Theme) => {
    return createStyles({
        root: {
            margin: 20
        }
    })
}

export default withStyles(mapStylesToProps)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Roles)))
