import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router-dom'
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {ConfigurableDetailsLabels} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableDetails/ConfigurableDetailsTypes";
import {ConfigurableDetails, ConfigurableDetailsTypes} from "@spitchlingware/ui-reusable-components";
import {ElementTypesEnum} from "../../../../utils/CommonTypes";
import {RoleModel} from "../../../../model/RoleModel";
import RoleDetailsTab from "./Tabs/RoleDetailsTab";
import {
    DetailsDispatchProps,
    DetailsStateProps
} from "@spitchlingware/ui-reusable-components/dist/components/Utils/ConfigurablesHelper";
import {withTranslation, WithTranslation} from "react-i18next";
import {commonDetailsDispatchProps, commonDetailsStateProps} from "../../../../utils/ReducerFunctions";
import {APThunkDispatch} from "../../../../store/ReducerTypes";


type RouteProps = {
    id: string
}

type OwnProps = {};
type StateProps = DetailsStateProps;
type DispatchProps = DetailsDispatchProps;

type Props = OwnProps & StateProps & DispatchProps & WithTranslation & RouteComponentProps<RouteProps>;
type State = {};

class RolesDetails extends Component<Props, State> {

    rolePrefix = 'roles.details';

    constructor(props: Props) {
        super(props);
        const element = this.getElement();
        if (!element) this.props.history.push(`/${ElementTypesEnum.ROLE}`);
    }

    prepareTabs = (): Array<ConfigurableDetailsTypes.ConfigurableTab> => {
        const {t} = this.props;
        return [
            {
                value: 0,
                label: t(`${this.rolePrefix}.tabs.general`),
                header: t(`${this.rolePrefix}.tabs.general`),
                content: (): React.ComponentType<ConfigurableDetailsTypes.ConfigurableContent> => {
                    return RoleDetailsTab;
                }
            }
        ];
    };

    labels = (): ConfigurableDetailsLabels => {
        const {t} = this.props;
        return {
            cancelButton: t(`${this.rolePrefix}.buttons.cancel`),
            saveButton: t(`${this.rolePrefix}.buttons.save`),
        }
    };

    onSave = (element: any): void => {
        this.props.updateElement(element, (saved) => {
            this.props.history.push(`/${ElementTypesEnum.ROLE}`);
        })
    };

    onCancel = (): void => {
        this.props.history.push(`/${ElementTypesEnum.ROLE}`);
    };

    getElement = (): RoleModel => {
        const {id} = this.props.match.params;
        const {elements} = this.props;
        return elements.find(e => e._id === id);
    };

    render() {
        return (
            <div>
                <ConfigurableDetails tabs={this.prepareTabs()}
                                     elementType={ElementTypesEnum.ROLE}
                                     data={undefined}
                                     labels={this.labels()}
                                     element={this.getElement()}
                                     onCancel={this.onCancel}
                                     onSave={this.onSave}/>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (state: any, ownProps: OwnProps): StateProps => {
    return commonDetailsStateProps(state, ElementTypesEnum.ROLE);
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: APThunkDispatch, ownProps: OwnProps): DispatchProps => {
    return commonDetailsDispatchProps(dispatch, ElementTypesEnum.ROLE)
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RolesDetails));
