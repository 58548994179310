import React, {FunctionComponent, useEffect, useState} from "react";
import {Button, createStyles, Paper, TextField, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {backend} from "../../../utils/Backend";
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import {OrganizationModel} from "../../../model/OrganizationModel";
import createValidatorFromOrg from "../../../utils/PasswordValidator";
import {useSelector} from "react-redux";
import {AppState} from "../../../store/ReducerTypes";
import {getOrgById} from "../../../utils/BackendUtils";

type CardProps = {
    title: string,
    paragraph: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: 700,
            '& .MuiTextField-root': {
                margin: theme.spacing(2)
            },
            margin: "auto",
            marginTop: "1em"
        },
        header: {
            margin: theme.spacing(2),
            fontSize: "1.3em"
        },
        buttons: {
            display: "flex",
            margin: theme.spacing(1),
            alignItems: "center"
        },
        alert: {
            margin: theme.spacing(1),
        }
    }),
);

type AlertState = {
    i18n: string
    type: 'success' | 'error'
}

export const ChangePassword: FunctionComponent<CardProps> = ({title, paragraph, children}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const user = useSelector((state: AppState) => state.general.user);
    const [current, setCurrent] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [alert, setAlert] = useState<AlertState | undefined>(undefined);
    const [org, setOrg] = useState<OrganizationModel>();

    // loading the org data the user belongs to
    useEffect(() => {
        if (user) {
            getOrgById(user.org_id, (data, error) => {
                if (!error) {
                    setOrg(data);
                }
            });
        }
    }, [user]);

    if (!org) {
        return <span/>;
    }

    const passwordValidator = createValidatorFromOrg(org);

    const changePassword = async () => {
        const res = await backend.change_password(current, password1);
        setAlert({
            type: res.data.success ? 'success' : 'error',
            i18n: res.data.i18n
        });
    }

    const getAlertMessage = () => {
        if (alert) {
            return <Alert className={classes.alert}
                          severity={alert.type}>{t(`change_password.messages.${alert.i18n}`)}</Alert>;
        }
        return undefined;
    }

    const passwordNotMatch = () => (password1 !== "" || password2 !== "") && password1 !== password2;

    const getPasswordPolicyError = (password: string) => {
        const result = passwordValidator.validate(password);
        if (!result.valid && password) {
            return <span>{result.errors.map((e, i) => <span style={{display: 'block'}} key={i}>{e}</span>)}</span>;
        }
        return undefined;
    }

    const getNotMatchError = () => {
        return passwordNotMatch() && t("change_password.messages.new_passwords_dont_match")
    }

    return <Paper className={classes.root}>
        <div className={classes.header}>{t('change_password.header')}</div>
        <TextField
            type={"password"}
            placeholder={t("change_password.current_password")}
            variant="standard"
            value={current}
            onChange={(e) => setCurrent(e.target.value)}
        />
        <TextField
            error={!!getPasswordPolicyError(password1)}
            type={"password"}
            placeholder={t("change_password.new_password")}
            variant="standard"
            value={password1}
            helperText={getPasswordPolicyError(password1)}
            onChange={(e) => setPassword1(e.target.value)}
        />
        <TextField
            error={passwordNotMatch()}
            type={"password"}
            placeholder={t("change_password.new_password")}
            helperText={getPasswordPolicyError(password2) || getNotMatchError()}
            variant="standard"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
        />
        {getAlertMessage()}
        <div className={classes.buttons}>
            <Button variant="contained" color="primary" style={{margin: "auto"}}
                    disabled={passwordNotMatch()
                    || current.length < org.settings.password_min_length
                    || !passwordValidator.validate(password1).valid
                    || !passwordValidator.validate(password2).valid}
                    onClick={changePassword}>
                {t("change_password.change_password_button")}
            </Button>
        </div>
    </Paper>;
}
