import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    TextField,
    Typography
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export type ActionComponent<T> = {
    render: ActionComponentRenderFunc<T>
}

export type ActionComponentCallback<T> = (value: T) => void;

export type ActionComponentRenderFunc<T> = (value: T, callback: ActionComponentCallback<T>) => any;

export type EditorMessages = {
    add_button: string,
    input_label?: string,
    key_value_field?: string,
    key_value_label?: string,
    key_value_icon?: string
};

type ElementType = 'select' | 'input' | 'key_value'

export type EditorConfig<T> = {
    type: ElementType
    currentItems: T[],
    allItems?: T[],
    onChange: (items: T[]) => void,
    actions?: ActionComponent<T>[],
    messages: EditorMessages
    editable?: boolean
};

type Props<T = any> = {
    config: EditorConfig<T>
};

const defaultSelected = (type: ElementType) => {
    if (type === 'key_value') return {
        key: "",
        value: ""
    }
    return "";
}

/**
 * An editor of list of objects.
 * Object must have the _id and name attributes.
 * Depending on the editor type the source of the values for list can be 'select' or 'input' element
 */
const ListEditor: React.FC<Props> = (props) => {
    const {config} = props;

    const [selected, setSelected] = useState<any>(defaultSelected(config.type));
    const [draggedElementIndex, setDraggedElementIndex] = useState<number | undefined>(undefined);
    const [elementOverIndex, setElementOverIndex] = useState<number | undefined>(undefined);

    useEffect(() => {
        setSelected(defaultSelected(config.type));
    }, [config.type]);

    const getAvailableItems = () => {
        switch (config.type) {
            case "key_value":
                return config.allItems
                    ? config.allItems.filter((e: any) => config.currentItems.findIndex((f: any) => e.value === f.key) === -1)
                    : [];
            default:
                return config.allItems
                    ? config.allItems
                        .filter((e: any) => config.currentItems.findIndex((f: any) => e._id === f._id) === -1) : [];
        }
    }

    const onDragStart = (event: any, index: number) => {
        setDraggedElementIndex(index);
    }

    const onDragEnd = (event: any, index: number) => {
        if (draggedElementIndex !== undefined && elementOverIndex !== undefined) {
            if (draggedElementIndex !== elementOverIndex) {
                let items = [...config.currentItems];
                items.splice(elementOverIndex, 0, items.splice(draggedElementIndex, 1)[0])
                config.onChange(items);
            }
        }
        setDraggedElementIndex(undefined)
        setElementOverIndex(undefined)
    }

    const onDragOver = (event: any, index: number) => {
        if (elementOverIndex === index) return;
        setElementOverIndex(index);
        event.preventDefault()
    }

    const onAdd = () => {
        if (selected !== '') {
            const items = [...config.currentItems];
            if (config.type === 'select') {
                const item = (config.allItems || []).find((e: any) => e._id === selected);
                if (item) items.push(item);
            } else if (config.type === 'key_value') {
                selected._id = selected.key;
                items.push(selected);
            } else {
                items.push({_id: selected, name: selected} as any);
            }
            setSelected(defaultSelected(config.type));
            config.onChange(items);
        }
    };

    const onDelete = (index: number) => {
        const items = [...config.currentItems];
        items.splice(index, 1);
        config.onChange(items);
    };

    const onUpdate = (item: any) => {
        const items = [...config.currentItems];
        const index = items.findIndex(e => e._id === item._id);
        if (index > -1) {
            items[index] = {...item};
            config.onChange(items);
        }
    };

    const getInputWidget = () => {
        let formControl = null;
        const availableItems = getAvailableItems();
        switch (config.type) {
            case "select":
                formControl =
                    <>
                        <TextField
                            select
                            value={selected}
                            size={"small"}
                            variant={"outlined"}
                            disabled={!config.editable}
                            style={{width: 300, margin: '0 5px'}}
                            onChange={event => setSelected(event.target.value)}
                        >
                            {availableItems.map((option, optIndex) => (
                                <MenuItem key={optIndex} value={option._id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormHelperText>{config.messages.input_label}</FormHelperText>
                    </>
                break;
            case "key_value":
                formControl = <Grid style={{
                    width: '100%'
                }} container spacing={3}>
                    <Grid item xs={4}>
                        <TextField
                            className={classes.listField}
                            select
                            fullWidth
                            label={props.config.messages.key_value_field}
                            disabled={!config.editable}
                            value={selected.key || ""}
                            size={"small"}
                            onChange={(event) => {
                                const value = event.target.value;
                                setSelected((prevState: any) => ({
                                        ...prevState,
                                        key: value
                                    })
                                )
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}>
                            {availableItems.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            className={classes.listField}
                            label={props.config.messages.key_value_label}
                            fullWidth
                            disabled={!config.editable}
                            value={selected.value || ""}
                            size={"small"}
                            onChange={(event) => {
                                const value = event.target.value;
                                setSelected((prevState: any) => ({
                                        ...prevState,
                                        value
                                    })
                                )
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                    </Grid>
                </Grid>
                break;
            case "input":
            default:
                formControl = <>
                    <TextField
                        value={selected}
                        variant={"outlined"}
                        size={"small"}
                        style={{width: 300, margin: '0 5px'}}
                        disabled={!config.editable}
                        onChange={(event: any) => setSelected(event.target.value)}
                        onKeyDown={(event: any) => {
                            // console.log(event.keyCode);
                            if (event.keyCode === 13) onAdd();
                        }}/>
                    <FormHelperText>{config.messages.input_label}</FormHelperText>
                </>
        }

        const isButtonDisabled = (): boolean => {
            if (!config.editable) return true;
            if (selected === '' && config.type === 'input') return true;
            if (availableItems.length === 0 && config.type === 'select') return true;
            if (config.type === 'key_value' && (selected.key === '' || selected.value === "")) return true;
            return false;
        }
        return <React.Fragment>
            <FormControl>
                {formControl}
            </FormControl>
            <Button
                color="primary"
                variant="outlined"
                disabled={isButtonDisabled()}
                onClick={() => onAdd()}>{config.messages.add_button}</Button>
        </React.Fragment>
    };

    const renderActions = (item: any) => {
        if (!config.actions) return null;
        return config.actions.map((e) => {
            return e.render(item, onUpdate);
        });
    };

    const getItemText = (item: any) => {
        if (config.type === 'key_value') return `${item.key} => ${item.value}`
        return item.name;
    }


    const classes = styles();
    return (
        <Card>
            <CardContent className={classes.disabled}>
                <CardHeader action={getInputWidget()}/>
                <Typography variant="body2" component="div">
                    <List dense={false}>
                        {config.currentItems.map((item: any, index: number) => {
                                const dragged = elementOverIndex === index && draggedElementIndex !== index;
                                return <ListItem
                                    key={index}
                                    className={dragged ? classes.dragged : undefined}
                                    divider={true}
                                    draggable
                                    onDragStart={(event) => {
                                        event.dataTransfer.effectAllowed = 'move';
                                        onDragStart(event, index)
                                    }}
                                    onDragOver={(event => onDragOver(event, index))}
                                    onDragEnd={(event) => onDragEnd(event, index)}>
                                    <ListItemAvatar className={classes.avatar}>
                                        <MenuIcon/>
                                    </ListItemAvatar>
                                    <ListItemText primary={getItemText(item)}/>
                                    <ListItemSecondaryAction>
                                        {renderActions(item)}
                                        <IconButton edge="end"
                                                    aria-label="delete"
                                                    disabled={!config.editable}
                                                    onClick={() => onDelete(index)}>
                                            <DeleteIcon color={"error"}/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            }
                        )}
                    </List>
                </Typography>
            </CardContent>
        </Card>
    );
};

const styles = makeStyles((theme: Theme) =>
    createStyles({
        dragged: {
            backgroundColor: '#ced4da'
        },
        avatar: {
            cursor: 'grab'
        },
        listField: {
            width: 200
        },
        disabled: {}
    })
);

export default ListEditor;