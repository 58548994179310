import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router-dom'
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {AppState, APThunkDispatch, CommonStateProps} from "../../../store/ReducerTypes";
import {validateStringField, validateUniqueField} from "../../../utils/TableUtils";
import {
    ConfigurableElementList,
    ConfigurableElementListTypes,
    ConfigurableTableTypes,
    Dialogs
} from "@spitchlingware/ui-reusable-components";
import {
    commonDeleteDialogLabels,
    commonDialogLabels,
    commonPaginatorLabels,
    commonPagingFeatures,
    commonToolbarLabels,
    ConfigurableDispatchProps
} from "@spitchlingware/ui-reusable-components/dist/components/Utils/ConfigurablesHelper";
import {withTranslation, WithTranslation} from "react-i18next";
import {ElementTypesEnum} from "../../../utils/CommonTypes";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {commonDispatchProps, commonStateProps} from "../../../utils/ReducerFunctions";
import {DialogConfigField} from "@spitchlingware/ui-reusable-components/dist/components/Commons/Dialogs/DialogTypes";
import {
    TableRowConfig
} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableTable/ConfigurableTableTypes";
import {AuthUser} from "../../../store/reducers/GeneralStore";

type OwnProps = {
    t: (value: string) => string
};
type StateProps = CommonStateProps & { user?: AuthUser }
type DispatchProps = ConfigurableDispatchProps & {};
type Props = OwnProps
    & StateProps
    & DispatchProps
    & WithStyles
    & WithTranslation
    & RouteComponentProps;
type State = {};

class Users extends Component<Props, State> {

    i18nPrefix = "users";

    prepareDialogConfig = (): Dialogs.DialogTypes.DialogConfig => {
        const {t, elements, orgDialogConfigField} = this.props;
        const org: DialogConfigField[] = orgDialogConfigField ? [orgDialogConfigField] : [];
        const fields: DialogConfigField[] = [
            ...org,
            {
                type: 'text',
                name: "firstName",
                label: t(`${this.i18nPrefix}.form_dialog.firstname`),
                required: true,
                maxLength: 100,
                validate: validateStringField,
                default: ''
            },
            {
                type: 'text',
                name: "lastName",
                label: t(`${this.i18nPrefix}.form_dialog.lastname`),
                required: true,
                maxLength: 100,
                validate: validateStringField,
                default: ''
            },
            {
                type: 'text',
                name: "email",
                label: t(`${this.i18nPrefix}.form_dialog.email`),
                required: true,
                maxLength: 100,
                validate: (obj, fieldName) => validateUniqueField(obj, fieldName, elements),
                default: ''
            }
        ];
        return {
            name: t(`${this.i18nPrefix}.form_dialog.title`),
            labels: commonDialogLabels(),
            fields: fields
        };
    };

    prepareTableConfig = (): ConfigurableTableTypes.TableConfig => {
        const {t, orgTableRowConfig, classes} = this.props;
        // const isEditable = isUserEditable(user);
        const org: TableRowConfig[] = orgTableRowConfig ? [orgTableRowConfig] : [];
        return {
            name: t(`${this.i18nPrefix}.table_config.title`),
            // validateAccess: (dbUser: UserModel) => {
            //     const allow = isEditable(dbUser);
            //     return {
            //         read: true,
            //         delete: allow,
            //         write: allow
            //     }
            // },
            selectedField: 'firstName',
            searchFields: ['firstName', 'lastName', 'email'],
            rows: [
                ...org,
                {
                    id: 'firstName',
                    type: ConfigurableTableTypes.RowType.text,
                    label: t(`${this.i18nPrefix}.table_config.rows.firstname`),
                },
                {
                    id: 'lastName',
                    type: ConfigurableTableTypes.RowType.text,
                    label: t(`${this.i18nPrefix}.table_config.rows.lastname`),
                },
                {
                    id: 'email',
                    type: ConfigurableTableTypes.RowType.text,
                    label: t(`${this.i18nPrefix}.table_config.rows.email`),
                }, {
                    id: 'groups',
                    type: ConfigurableTableTypes.RowType.text,
                    label: t(`${this.i18nPrefix}.table_config.rows.groups`),
                    resolve: element => {
                        return element.groups.map((e: any) => e.name).join(', ');
                    }
                },
                {
                    id: 'isAdmin',
                    type: ConfigurableTableTypes.RowType.text,
                    label: t(`${this.i18nPrefix}.table_config.rows.isAdmin.label`),
                    resolve: element => {
                        return t(`${this.i18nPrefix}.table_config.rows.isAdmin.${element.isAdmin}`);
                    }
                },
                {
                    id: 'disabled',
                    type: ConfigurableTableTypes.RowType.text,
                    label: t(`${this.i18nPrefix}.table_config.rows.disabled.label`),
                    resolve: element => {
                        return <span className={element.disabled ? classes.disabled : ''}>
                            {t(`${this.i18nPrefix}.table_config.rows.disabled.${element.disabled}`)}
                        </span>;
                    }
                },
                {
                    caption: t(`${this.i18nPrefix}.table_config.rows.details`),
                    label: t(`${this.i18nPrefix}.table_config.rows.details`),
                    type: ConfigurableTableTypes.RowType.button,
                    action: (element: any, event: any) => {
                        this.props.history.push(`/${ElementTypesEnum.USER}/${element._id}`);
                    }
                }
            ]
        };
    };

    createElement = (item: any) => {
        this.props.updateElement(item, (saved) => {
            this.props.history.push(`/${ElementTypesEnum.USER}/${saved._id}`);
        });
    };

    refresh = () => {
        this.props.refresh();
    };


    render() {
        const {elements, updateElement, deleteElement, t, classes} = this.props;

        const dialogParameters: ConfigurableElementListTypes.DialogParameters = {
            config: this.prepareDialogConfig,
            onCreate: this.createElement,
            onUpdate: updateElement,
            onDelete: deleteElement,
            onRefresh: this.refresh,
            deleteLabels: commonDeleteDialogLabels(t),
            dialogLabels: commonDialogLabels(t),
        };

        const tableParameters: ConfigurableElementListTypes.TableParameters = {
            config: this.prepareTableConfig,
            data: elements,

            rowsPerPage: 10,
            searchable: true,
            selectable: true,
            expandable: false,
            paginatorLabels: commonPaginatorLabels(t),
            toolbarLabels: commonToolbarLabels(t),
            pagingFeatures: commonPagingFeatures(),
            //state: table,
            //changeState: updateTableState,

            externalPaging: false,
            //extOnPageChange: (p1: any, p2: number) => {},
            //extPageNumber: 0,
            //extTotalRows: 0,
        };

        return (
            <div className={classes.root}>
                <ConfigurableElementList
                    dialogParameters={dialogParameters}
                    tableParameters={tableParameters}/>
            </div>
        );
    }
}

const elementType = ElementTypesEnum.USER;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state: AppState, ownProps: OwnProps): StateProps => {
    return {
        user: state.general.user,
        ...commonStateProps(state, elementType)
    }
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: APThunkDispatch, ownProps: OwnProps): DispatchProps => {
    return commonDispatchProps(dispatch, elementType)
};


const mapStylesToProps = (theme: Theme) => {
    return createStyles({
        root: {
            margin: 20
        },
        disabled: {
            color: theme.palette.error.main
        }
    })
}

export default withStyles(mapStylesToProps)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users)))
