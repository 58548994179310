import Welcome from "../components/RoutedComponents/Welcome/Welcome";
import Organizations from "../components/RoutedComponents/Organizations/Organizations";
import Groups from "../components/RoutedComponents/Groups/Groups";
import Users from "../components/RoutedComponents/Users/Users";
import {
    ActionTypesEnum,
    AdminHasAccess,
    AnyUserHasAccess,
    AppRoute,
    ElementTypesEnum,
    SuperHasAccess
} from "./CommonTypes";

import OrgIcon from '@material-ui/icons/Business';
import GroupIcon from '@material-ui/icons/Contacts';
import UserIcon from '@material-ui/icons/People';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PersonIcon from '@material-ui/icons/Person';
import SecurityIcon from '@material-ui/icons/Security';
import FeaturesIcon from '@material-ui/icons/Toc';

import OrganizationsDetails
    from "../components/RoutedComponents/Organizations/OrganizationsDetails/OrganizationsDetails";
import GroupsDetails from "../components/RoutedComponents/Groups/GroupsDetails/GroupsDetails";
import UsersDetails from "../components/RoutedComponents/Users/UsersDetails/UsersDetails";
import {AuthUser} from "../store/reducers/GeneralStore";
import Roles from "../components/RoutedComponents/Roles/Roles";
import RolesDetails from "../components/RoutedComponents/Roles/RolesDetails/RolesDetails";
import {ChangePassword} from "../components/RoutedComponents/ChangePassword/ChangePassword";
import UserProfile from "../components/RoutedComponents/UserProfile/UserProfile";
import Features from "../components/RoutedComponents/Feature/Features";

const ROUTES: Array<AppRoute> = [
    {
        exact: true,
        menu: false,
        name: "",
        path: "/",
        icon: null,
        component: Welcome,
        permissions: AnyUserHasAccess
    },
    {
        exact: true,
        menu: true,
        name: "organization",
        path: `/${ElementTypesEnum.ORGANIZATION}`,
        icon: OrgIcon,
        component: Organizations,
        permissions: SuperHasAccess
    },
    {
        exact: true,
        menu: false,
        name: "organization",
        path: `/${ElementTypesEnum.ORGANIZATION}/:id`,
        icon: null,
        component: OrganizationsDetails,
        permissions: AdminHasAccess
    },

    {
        exact: true,
        menu: true,
        name: "group",
        path: `/${ElementTypesEnum.GROUP}`,
        icon: GroupIcon,
        component: Groups,
        permissions: AdminHasAccess,
        needSelectedOrg: true
    },
    {
        exact: true,
        menu: false,
        name: "group",
        path: `/${ElementTypesEnum.GROUP}/:id`,
        icon: null,
        component: GroupsDetails,
        permissions: AdminHasAccess,
        needSelectedOrg: true
    },
    {
        exact: true,
        menu: true,
        name: "user",
        path: `/${ElementTypesEnum.USER}`,
        icon: UserIcon,
        component: Users,
        permissions: AdminHasAccess
    },
    {
        exact: true,
        menu: false,
        name: "user",
        path: `/${ElementTypesEnum.USER}/:id`,
        icon: null,
        component: UsersDetails,
        permissions: AdminHasAccess
    },
    {
        exact: true,
        menu: true,
        name: "role",
        path: `/${ElementTypesEnum.ROLE}`,
        icon: PermIdentityIcon,
        component: Roles,
        permissions: AdminHasAccess,
        needSelectedOrg: true
    },
    {
        exact: true,
        menu: false,
        name: "role",
        path: `/${ElementTypesEnum.ROLE}/:id`,
        icon: null,
        component: RolesDetails,
        permissions: AdminHasAccess
    },
    {
        exact: true,
        menu: true,
        name: "feature",
        path: `/${ElementTypesEnum.FEATURE}`,
        icon: FeaturesIcon,
        component: Features,
        permissions: AdminHasAccess,
        needSelectedOrg: true
    },
    {
        exact: true,
        menu: false,
        name: "role",
        path: `/${ElementTypesEnum.ROLE}/:id`,
        icon: null,
        component: RolesDetails,
        permissions: AdminHasAccess
    },
    {
        exact: true,
        menu: true,
        name: "user_profile",
        path: `/${ActionTypesEnum.USER_PROFILE}`,
        icon: PersonIcon,
        component: UserProfile,
        permissions: AnyUserHasAccess,
        needSelectedOrg: true
    },
    {
        exact: true,
        menu: true,
        name: "change_password",
        path: `/${ActionTypesEnum.CHANGE_PASSWORD}`,
        icon: SecurityIcon,
        component: ChangePassword,
        permissions: AnyUserHasAccess
    }
];

export const getRoutes = (user?: AuthUser, currentOrg?: string) => {
    if (!user) {
        return [];
    }
    return ROUTES.filter(
        r => (r.permissions.user) ||
            (r.permissions.admin && (user.is_admin || user.is_super)) ||
            (r.permissions.super && user.is_super)
    ).filter(r => !(r.needSelectedOrg && user.is_super && !currentOrg));
}
