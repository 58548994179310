import React, {FC, useState} from "react";
import {
    createStyles,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Paper,
    TextField
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {FeatureGroupModel} from "../../../../../../model/FeatureGroupModel";

type Props = {
    availableGroups: FeatureGroupModel[],
    assignedGroupsId: string[],
    currentGroup: string,
    onSelect: (groupId: string) => void,
    onAddGroup: (groupId: string) => void,
    onDeleteGroup: (groupId: string) => void,
    labels: {
        select_group: string
    }
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginRight: 5,
        width: 360
    },
    titleContainer: {
        display: 'flex',
        padding: 5,
        alignItems: 'center'
    },
    title: {
        flex: 1
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

const GroupList: FC<Props> = (props) => {
    const {availableGroups, assignedGroupsId, onSelect, onAddGroup, onDeleteGroup, labels, currentGroup} = props;
    const classes = useStyles();
    const [addGroup, setAddGroup] = useState('');

    const getGroups = (assigned: boolean) => {
        if (assigned) {
            return availableGroups.filter(e => assignedGroupsId.findIndex(a => a === e._id) !== -1)
        }
        return availableGroups.filter(e => assignedGroupsId.findIndex(a => a === e._id) === -1)
    }

    const getGroupList = () => <List className={classes.list}
                                     component="nav">{getGroups(true).map(value => {
        return <ListItem button key={value._id}
                         onClick={() => {
                             onSelect(value._id)
                         }}
                         selected={currentGroup === value._id}>
            <ListItemText primary={value.title}/>

            {currentGroup === value._id && <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                    <DeleteIcon onClick={() => onDeleteGroup(value._id)}/>
                </IconButton>
            </ListItemSecondaryAction>}

        </ListItem>;
    })}
    </List>

    return (
        <Paper className={classes.root}>
            <div className={classes.titleContainer}>
                <TextField
                    label={labels.select_group}
                    select
                    variant={"outlined"}
                    fullWidth
                    value={addGroup}
                    margin="dense">
                    {getGroups(false).map((option: FeatureGroupModel) => (
                        <MenuItem key={option._id} value={option._id} onClick={() => setAddGroup(option._id)}>
                            {option.title}
                        </MenuItem>
                    ))}
                </TextField>
                <IconButton color={"primary"} disabled={!addGroup}>
                    <AddIcon onClick={() => {
                        onAddGroup(addGroup);
                        setAddGroup('')
                    }}/>
                </IconButton>
            </div>
            {getGroupList()}
        </Paper>
    );

}

export default GroupList;
