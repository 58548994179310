import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {ConfigurableContent} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableDetails/ConfigurableDetailsTypes";
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {Action, Dispatch} from "redux";
import styleList from "./GroupRolesTab.module.scss";
import {RoleModel} from "../../../../../model/RoleModel";
import ListEditor from "../../../../ListEditor/ListEditor";
import {AppState} from "../../../../../store/ReducerTypes";

type OwnProps = {};
type StateProps = {
    roles: RoleModel[]
}
type DispatchProps = {};
type Props = ConfigurableContent & WithTranslation & OwnProps & StateProps & DispatchProps;
type State = {}

class GroupRolesTab extends React.Component<Props, State> {

    helpPrefix = 'groups.details.roles';

    onChange = (items: RoleModel[]) => {
        this.props.onChange('roles', items.map((e: RoleModel) => ({_id: e._id, name: e.name})));
    };

    resolveObjects = (): { _id: string, name: string }[] => {
        const {element, roles} = this.props;
        return element.roles.filter((r: any) => roles.findIndex(e => e._id === r._id) > -1);
    };

    render() {
        const {t, roles} = this.props;
        return (<div className={styleList.container}>
            <ListEditor
                config={{
                    type: 'select',
                    allItems: roles.map(e => ({_id: e._id, name: e.name})),
                    currentItems: this.resolveObjects(),
                    onChange: this.onChange,
                    editable: true,
                    messages: {
                        add_button: t(`${this.helpPrefix}.add_button`),
                        input_label: t(`${this.helpPrefix}.input_label`)
                    }
                }}
            />
        </div>)
    };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (state: AppState, ownProps: OwnProps): StateProps => {
    const roles = state.data.role;
    return {
        roles
    }
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: Dispatch<Action<any>>, ownProps: OwnProps): DispatchProps => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GroupRolesTab))