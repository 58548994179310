import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {ConfigurableContent} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableDetails/ConfigurableDetailsTypes";
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {Action, Dispatch} from "redux";
import styleList from "./GroupFeaturesTab.module.scss";
import {AppState} from "../../../../../store/ReducerTypes";
import GroupList from "./utils/GroupList";
import {FeatureGroupModel} from "../../../../../model/FeatureGroupModel";
import {FeatureModel} from "../../../../../model/FeatureModel";
import {GroupedFeatures} from "../../../../../model/GroupModel";
import {v4} from 'uuid';
import {ConfigurableVerticalTable} from "@spitchlingware/ui-reusable-components";
import {ConfigurableVerticalTableConfigType} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableVerticalTable/ConfigurableVerticalTableTypes";
import TypedValueEditor from "../../../../Misc/TypedValueEditor";
import {Paper} from "@material-ui/core";

type OwnProps = {};
type StateProps = {
    featureGroups: FeatureGroupModel[],
    features: FeatureModel[]
}
type DispatchProps = {};
type Props = ConfigurableContent & WithTranslation & OwnProps & StateProps & DispatchProps;
type State = {
    currentGroup: string
}

class GroupFeaturesTab extends React.Component<Props, State> {

    helpPrefix = 'groups.details.features';

    state: State = {
        currentGroup: ''
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<State>) {
        if (!state.currentGroup &&
            props.featureGroups.length > 0 &&
            props.element.features &&
            Object.keys(props.element.features).length > 0) {
            return {
                currentGroup: props.featureGroups[0]._id
            }
        }
        return null;
    }

    t = (id: string) => this.props.t(`${this.helpPrefix}.${id}`);

    setCurrentGroup = (id: string) => this.setState({currentGroup: id});

    onChange = (items: GroupedFeatures) => {
        this.props.onChange('features', items);
    };

    saveFeature = (groupId: string, id: string | undefined, value: any = undefined) => {
        const features = this.props.element.features || {};
        const items = {...features};
        if (!id) {
            delete items[groupId];
        } else {
            if (!items[groupId]) {
                items[groupId] = {};
            } else {
                items[groupId] = {...items[groupId]}
            }
            if (value !== undefined) {
                items[groupId][id] = value;
            } else {
                delete items[groupId][id];
            }
        }
        this.onChange(items);
    }

    onAddGroup = (groupId: string) => {
        this.saveFeature(groupId, '_id', v4());
        this.setCurrentGroup(groupId);
    }

    onDeleteGroup = (groupId: string) => {
        this.saveFeature(groupId, undefined);
        this.setCurrentGroup("");
    }

    onEditFeature = (featureId: string, value: any) => {
        this.saveFeature(this.state.currentGroup, featureId, value);
    }

    resolveFeatureValue = (featureId: string) => {
        const assignedFeatures = this.props.element.features || {};
        const {features} = this.props;
        const {currentGroup} = this.state;
        const value = assignedFeatures[currentGroup] && assignedFeatures[currentGroup][featureId];
        if (value === undefined) {
            const defaultValue = features.find(e => e._id === featureId)?.default_value;
            return {
                value: defaultValue !== undefined ? defaultValue : '',
                isDefault: true
            };
        }
        return {
            value,
            isDefault: false
        };
    }

    getTableConfig = (): Array<ConfigurableVerticalTableConfigType> => {
        const {currentGroup} = this.state;
        const features = this.props.features || [];
        return features.filter(e => e.group_id === currentGroup).map((e: FeatureModel): ConfigurableVerticalTableConfigType => {
            return {
                key: '_id',
                label: e.title,
                type: 'custom',
                render_func: (obj, name, onChangeField) => {
                    const resolvedValue = this.resolveFeatureValue(e._id);
                    return <TypedValueEditor
                        value={resolvedValue.value}
                        isDefault={resolvedValue.isDefault}
                        enableReset={true}
                        type={e.type}
                        options={e.settings?.values || []}
                        onChange={value => {
                            this.onEditFeature(e._id, value);
                        }}
                        labels={
                            {
                                input_label: '',
                                default_value_title: this.t('editor.default_value_title'),
                                buttons: {
                                    reset: this.t('editor.buttons.reset')
                                }
                            }
                        }
                    />;
                },
                editable: true
            };
        });
    }

    getCurrentGroup = () => {
        return this.props.featureGroups.find(g => g._id === this.state.currentGroup);
    }

    getTable = () => {
        const {element} = this.props;
        const {currentGroup} = this.state;
        if (currentGroup) {
            return <ConfigurableVerticalTable name={this.getCurrentGroup()?.title || ''}
                                              config={this.getTableConfig()}
                                              onChange={() => ({})}
                                              data={element}
                                              permissions={this.props.permissions}
            />;
        }
        return null;
    }

    render() {
        const {featureGroups, element} = this.props;
        return (<Paper className={styleList.container}>
            <GroupList
                onAddGroup={this.onAddGroup}
                assignedGroupsId={Object.keys(element.features || {})}
                availableGroups={featureGroups}
                currentGroup={this.state.currentGroup}
                onDeleteGroup={this.onDeleteGroup}
                onSelect={this.setCurrentGroup}
                labels={{
                    select_group: this.t('group_list.select_group')
                }}
            />
            <div className={styleList.table}>
                {this.getTable()}
            </div>
        </Paper>)
    }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (state: AppState, ownProps: OwnProps): StateProps => {
    const featureGroups = state.data.feature_group;
    const features = state.data.feature;
    return {
        featureGroups,
        features
    }
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: Dispatch<Action<any>>, ownProps: OwnProps): DispatchProps => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GroupFeaturesTab))
