import React from "react";

type RoutePermissions = {
    admin: boolean
    super: boolean
    user: boolean
}

export const AnyUserHasAccess: RoutePermissions = {
    admin: true,
    super: true,
    user: true
}

export const AdminHasAccess: RoutePermissions = {
    admin: true,
    super: true,
    user: false
}

export const SuperHasAccess: RoutePermissions = {
    admin: false,
    super: true,
    user: false
}

export interface AppRoute {
    exact: boolean,
    menu: boolean,
    name: string,
    path: string,
    icon: React.ElementType | null,
    component: React.ComponentClass<any, any> | React.FunctionComponent<any>
    permissions: RoutePermissions,
    needSelectedOrg?: boolean
}

export enum ElementTypesEnum {
    USER = "user",
    GROUP = "group",
    ORGANIZATION = "organization",
    ROLE = "role",
    FEATURE = "feature",
    FEATURE_GROUP = "feature_group"
}

export enum ActionTypesEnum {
    LOGIN = 'login',
    LOGOUT = 'logout',
    CHANGE_PASSWORD = 'change_password',
    USER_PROFILE = 'user_profile',
    APP_SETTINGS = 'app_settings',
    REFRESH_TOKEN = 'refresh_token',
    USER_DATA = 'user_data'
}

export enum FeatureTypeEnum {
    string = "string",
    number = "number",
    range = "range",
    boolean = "boolean",
    list = "list"
}
