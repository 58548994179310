import i18n from '../i18n';
import {OrganizationModel} from "../model/OrganizationModel";

export type ValidatorSettings = {
    min?: number;
    max?: number;
    lowerCase?: number;
    upperCase?: number;
    numeric?: number;
    symbol?: number;
}

export type ValidatorMessages = {
    too_short: string;
    too_long: string;
    lowercase: string;
    uppercase: string;
    numeric: string;
    symbol: string;
}

const replace = (text: string, params: Record<string, string | number>) => {
    Object.keys(params).forEach(p => {
        text = text.replaceAll('{' + p + '}', params[p] + '');
    });
    return text;
}

class PasswordValidator {
    constructor(private settings: ValidatorSettings, private messages: ValidatorMessages) {
    }

    public validate(value: string) {
        const {
            min = 0,
            max = 0,
            lowerCase = 0,
            upperCase = 0,
            numeric = 0,
            symbol = 0
        } = this.settings;

        const errors = [];
        const lowercaseCount = value.match(/[a-z]/g)?.length ?? 0;
        const upperCaseCount = value.match(/[A-Z]/g)?.length ?? 0;
        const numericCount = value.match(/[0-9]/g)?.length ?? 0;
        const symbolCount = value.match(/[^a-zA-Z0-9]/g)?.length ?? 0;

        const meetsMin = min && value.length >= min;
        const meetsMax = max && value.length <= max;

        const meetsLowercase = lowercaseCount >= (lowerCase);
        const meetsUppercase = upperCaseCount >= (upperCase);
        const meetsNumeric = numericCount >= (numeric);
        const meetsSymbol = symbolCount >= (symbol);

        if (!meetsMin) errors.push(replace(this.messages.too_short, {value: min}));
        if (!meetsMax) errors.push(replace(this.messages.too_long, {value: max}));
        if (!meetsLowercase) errors.push(replace(this.messages.lowercase, {value: lowerCase}));
        if (!meetsUppercase) errors.push(replace(this.messages.uppercase, {value: upperCase}));
        if (!meetsNumeric) errors.push(replace(this.messages.numeric, {value: numeric}));
        if (!meetsSymbol) errors.push(replace(this.messages.symbol, {value: symbol}));

        return {
            valid: errors.length === 0,
            errors
        }
    }
}

export default function createValidatorFromOrg(org: OrganizationModel) {
    return new PasswordValidator({
        min: org.settings.password_min_length,
        max: org.settings.password_max_length,
        lowerCase: org.settings.password_lowercase,
        upperCase: org.settings.password_uppercase,
        numeric: org.settings.password_numeric,
        symbol: org.settings.password_symbol
    }, {
        too_short: i18n.t('password.messages.too_short'),
        too_long: i18n.t('password.messages.too_long'),
        lowercase: i18n.t('password.messages.lowercase'),
        uppercase: i18n.t('password.messages.uppercase'),
        numeric: i18n.t('password.messages.numeric'),
        symbol: i18n.t('password.messages.symbol')
    });
}
