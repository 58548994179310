import React, {Component} from 'react';
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {AppState, APThunkDispatch, CommonStateProps} from "../../../store/ReducerTypes";
import {
    commonDeleteDialogLabels,
    ConfigurableDispatchProps
} from "@spitchlingware/ui-reusable-components/dist/components/Utils/ConfigurablesHelper";
import {WithTranslation, withTranslation} from "react-i18next";
import {ElementTypesEnum} from "../../../utils/CommonTypes";
import {commonDispatchProps, commonStateProps} from "../../../utils/ReducerFunctions";
import {
    createStyles,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Theme,
    Typography,
    WithStyles,
    withStyles
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

import GroupEditDialog from "./GroupEditDialog";
import {DeleteDialog} from "@spitchlingware/ui-reusable-components/dist/components/Commons/Dialogs";
import {FeatureGroupModel} from "../../../model/FeatureGroupModel";
import {validateUniqueField} from "../../../utils/TableUtils";

type OwnProps = {
    t: (value: string) => string,
    onSelected: (id: string) => void,
    onDelete: (id: string | undefined) => void,
    editable: boolean
};

type StateProps = CommonStateProps & {}

type DispatchProps = ConfigurableDispatchProps & {};

type Props = OwnProps
    & StateProps
    & DispatchProps
    & WithStyles
    & WithTranslation;

type DialogType = 'edit' | 'delete';

type State = {
    selectedId: string,
    currentItem: FeatureGroupModel | undefined
} & Record<DialogType, boolean>

class GroupList extends Component<Props, State> {

    i18nPrefix = "features.groups";

    state: State = {
        selectedId: "",
        currentItem: undefined,
        edit: false,
        delete: false
    }

    refresh = () => {
        this.props.refresh();
    };

    t = (id: string) => {
        return this.props.t(`${this.i18nPrefix}.${id}`);
    }

    getGroupById = (id: string): FeatureGroupModel => {
        return this.props.elements.find(e => e._id === id);
    }

    handleListItemClick = (id: string) => {
        this.setState({
            selectedId: id
        });
        this.props.onSelected(id);
    };

    cancelDialog = (dialog: DialogType) => {
        const state: any = {
            [dialog]: false,
            currentItem: undefined
        };
        this.setState(state);
    }

    openDialog = (dialog: DialogType, data: FeatureGroupModel | undefined) => {
        const state: any = {
            [dialog]: true,
            currentItem: data || {}
        };
        this.setState(state);
    }

    saveGroup = (data: FeatureGroupModel) => {
        this.props.updateElement(data, item => {
            const state: any = {
                edit: false,
                currentItem: undefined,
                selected: item._id
            };
            this.setState(state);
        });
    }

    deleteGroup = () => {
        this.props.onDelete(this.state.currentItem?._id);
        this.props.deleteElement(this.state.currentItem, item => {
            this.setState({
                delete: false
            });
        });
        this.props.onSelected('');
    }

    getGroupEditDialog = () => {
        const {currentItem, edit} = this.state;
        const element = this.getGroupById(currentItem?._id || '') || {};
        return <GroupEditDialog t={this.t} open={edit} data={element}
                                cancel={() => this.cancelDialog('edit')}
                                confirm={this.saveGroup}
                                validateGroupName={(element, fieldName) => {
                                    const value = element[fieldName] || '';
                                    return validateUniqueField(element, fieldName, this.props.elements) && value.match(/^[a-zA-z0-9_]+$/)
                                }}
        />
    }

    getDeleteDialog = () => {
        return <DeleteDialog open={this.state.delete}
                             cancel={this.cancelDialog.bind(this, 'delete')}
                             confirm={this.deleteGroup}
                             labels={commonDeleteDialogLabels()}
        />;
    }

    render() {
        const {elements, classes, editable} = this.props;
        const {selectedId} = this.state;
        return (
            <Paper className={classes.root}>
                {this.getGroupEditDialog()}
                {this.getDeleteDialog()}
                {editable && <div className={classes.titleContainer}>
                    <Typography variant="h6" className={classes.title}>
                        {this.t('title')}
                    </Typography>
                    <IconButton className={classes.addButton} onClick={() => this.openDialog('edit', undefined)}>
                        <AddIcon/>
                    </IconButton>
                </div>}
                <List className={classes.list} component="nav">
                    <ListItem key={"none"} button onClick={() => this.handleListItemClick("")}
                              selected={selectedId === ""}>
                        <ListItemText primary={this.t('all_features')}/>
                    </ListItem>
                    {elements.map(value => {
                        return <ListItem key={value._id} button
                                         onClick={() => this.handleListItemClick(value._id)}
                                         selected={selectedId === value._id}>
                            <ListItemText primary={value.title}/>

                            {selectedId === value._id && <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete"
                                            onClick={() => this.openDialog('edit', value)}>
                                    <EditIcon/>
                                </IconButton>
                                <IconButton edge="end" aria-label="delete"
                                            onClick={() => this.openDialog('delete', value)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>}
                        </ListItem>;
                    })}
                </List>
            </Paper>
        );
    }
}

const featureGroupType = ElementTypesEnum.FEATURE_GROUP;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state: AppState, ownProps: OwnProps): StateProps => {
    return commonStateProps(state, featureGroupType);
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: APThunkDispatch, ownProps: OwnProps): DispatchProps => {
    return commonDispatchProps(dispatch, featureGroupType)
};


const mapStylesToProps = (theme: Theme) => {
    return createStyles({
        root: {
            marginRight: 5,
            width: 360
        },
        titleContainer: {
            display: 'flex',
            padding: 5,
            alignItems: 'center',
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
        addButton: {
            color: 'white'
        },
        title: {
            flex: 1
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
    })
}

export default withStyles(mapStylesToProps)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GroupList)))
