import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router-dom'
import {AppState, APThunkDispatch, CommonStateProps} from "../../../store/ReducerTypes";
import {
    commonDeleteDialogLabels,
    commonDialogLabels,
    commonPaginatorLabels,
    commonPagingFeatures,
    commonToolbarLabels,
    ConfigurableDispatchProps
} from "@spitchlingware/ui-reusable-components/dist/components/Utils/ConfigurablesHelper";
import {withTranslation, WithTranslation} from "react-i18next";
import {withStyles, WithStyles} from "@material-ui/core";
import {
    ConfigurableElementList,
    ConfigurableElementListTypes,
    ConfigurableTableTypes,
    Dialogs
} from "@spitchlingware/ui-reusable-components";
import {validateUniqueField} from "../../../utils/TableUtils";
import {parametrizedOptions} from "../../../utils/CommonFunctions";
import KeyValueListEditor from "../../Misc/KeyValueListEditor";
import GroupList from "./GroupList";
import {ElementTypesEnum, FeatureTypeEnum} from "../../../utils/CommonTypes";
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {commonDispatchProps, commonStateProps} from "../../../utils/ReducerFunctions";
import {createStyles, Theme} from "@material-ui/core/styles";
import TypedValueEditor from "../../Misc/TypedValueEditor";

type OwnProps = {
    t: (value: string) => string
};

type StateProps = CommonStateProps & {
    feature_groups: Array<any>
};

type DispatchProps = ConfigurableDispatchProps & {};

type Props = OwnProps
    & StateProps
    & DispatchProps
    & WithStyles
    & WithTranslation
    & RouteComponentProps;

type State = {
    currentGroup: string
};

class Features extends Component<Props, State> {

    i18nPrefix = "features";

    state: State = {
        currentGroup: ""
    }

    t = (id: string) => this.props.t(`${this.i18nPrefix}.${id}`);

    getGroupName = (id: string) => {
        return this.props.feature_groups.find(e => e._id === id)?.group_name || ''
    }

    prepareDialogConfig = (): Dialogs.DialogTypes.DialogConfig => {
        const {elements} = this.props;
        return {
            name: this.t(`form_dialog.title`),
            labels: commonDialogLabels(),
            fields: [
                {
                    type: 'text',
                    name: "title",
                    label: this.t(`form_dialog.fields.title`),
                    required: true,
                    maxLength: 100,
                    validate: (obj, fieldName) => validateUniqueField(obj, fieldName, elements),
                    default: ''
                },
                {
                    type: 'text',
                    name: "name",
                    label: this.t(`form_dialog.fields.name`),
                    required: true,
                    maxLength: 100,
                    validate: (obj, fieldName) => validateUniqueField(obj, fieldName, elements),
                    default: ''
                },
                {
                    type: 'select',
                    name: "type",
                    label: this.t(`form_dialog.fields.type`),
                    persistent: true,
                    options: () => parametrizedOptions(FeatureTypeEnum, `${this.i18nPrefix}.enums.types`),
                    required: true,
                    default: FeatureTypeEnum.string
                },
                {
                    type: 'custom',
                    name: 'default_value',
                    label: '',
                    required: false,
                    render_func: (value, onChange, data) => {
                        return <TypedValueEditor
                            value={value}
                            enableReset={false}
                            type={data.type}
                            options={data.settings?.values}
                            onChange={onChange}
                            labels={{
                                input_label: this.t(`form_dialog.fields.default_value`)
                            }}
                        />
                    }
                },
                {
                    type: 'custom',
                    name: 'settings.values',
                    label: '',
                    required: false,
                    render_func: (value: any, onChange) => <KeyValueListEditor
                        labels={{
                            add_label: 'Add',
                            tag_id_input: 'id',
                            tag_label_input: 'label'
                        }}
                        values={value || []}
                        onChange={onChange}
                    />,
                    is_show_func: data => data.type === FeatureTypeEnum.list
                }
            ]
        };
    };

    prepareTableConfig = (): ConfigurableTableTypes.TableConfig => {
        const {currentGroup} = this.state;
        return {
            name: this.t(`table_config.title`),
            selectedField: 'name',
            searchFields: ['name', 'title'],
            buttons: {
                addEnabled: !!currentGroup,
                deleteEnabled: true,
                editEnabled: true
            },
            rows: [
                {
                    id: 'title',
                    type: ConfigurableTableTypes.RowType.text,
                    label: this.t(`table_config.rows.title`),
                },
                {
                    id: 'name',
                    type: ConfigurableTableTypes.RowType.text,
                    label: this.t(`table_config.rows.name`),
                    resolve: element => {
                        return `${this.getGroupName(element.group_id)}.${element.name}`
                    }
                },
                {
                    id: 'type',
                    type: ConfigurableTableTypes.RowType.text,
                    label: this.t(`table_config.rows.type`),
                }
            ]
        };
    };

    refresh = () => {
        this.props.refresh();
    };

    handleGroupSelection = (id: string) => {
        this.setState({
            currentGroup: id
        });
    }

    render() {
        const {elements, updateElement, deleteElement, createElement, t, classes} = this.props;
        const {currentGroup} = this.state;

        const dialogParameters: ConfigurableElementListTypes.DialogParameters = {
            config: this.prepareDialogConfig,
            onCreate: (item) => {
                item.group_id = this.state.currentGroup;
                createElement(item);
            },
            onUpdate: updateElement,
            onDelete: deleteElement,
            onRefresh: this.refresh,
            deleteLabels: commonDeleteDialogLabels(t),
            dialogLabels: commonDialogLabels(t),
        };

        const tableParameters: ConfigurableElementListTypes.TableParameters = {
            config: this.prepareTableConfig,
            data: elements.filter(e => e.group_id === currentGroup || !currentGroup),
            rowsPerPage: 20,
            searchable: true,
            selectable: true,
            expandable: false,
            paginatorLabels: commonPaginatorLabels(t),
            toolbarLabels: commonToolbarLabels(t),
            pagingFeatures: commonPagingFeatures(),
            externalPaging: false,
        };

        return (
            <div className={classes.root}>
                <GroupList
                    t={this.props.t}
                    editable={true}
                    onSelected={this.handleGroupSelection}
                    onDelete={() => this.refresh()}
                />
                <div className={classes.table}>
                    <ConfigurableElementList
                        dialogParameters={dialogParameters}
                        tableParameters={tableParameters}/>
                </div>
            </div>
        );
    }
}

const featureType = ElementTypesEnum.FEATURE;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state: AppState,
                                                                          ownProps: OwnProps): StateProps => {
    return {
        ...commonStateProps(state, featureType),
        feature_groups: state.data.feature_group
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: APThunkDispatch, ownProps: OwnProps): DispatchProps => {
    return commonDispatchProps(dispatch, featureType);
};


const mapStylesToProps = (theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            margin: 20,
            width: '95%'
        },
        table: {
            width: '100%'
        }
    })
}

export default withStyles(mapStylesToProps)(connect(mapStateToProps,
    mapDispatchToProps)(withTranslation()(Features)))
