import {
    COOKIE_REFRESH_TOKEN_NAME,
    COOKIE_TOKEN_NAME,
    STORAGE_CURRENT_LANGUAGE,
    STORAGE_CURRENT_ORG
} from "../utils/Constants";
import Cookies from 'js-cookie';

const defaultLanguage = 'ru-ru';

class SessionServiceClass {
    language = () => {
        return sessionStorage.getItem(STORAGE_CURRENT_LANGUAGE) || defaultLanguage;
    }
    saveLanguage = (lang: string) => {
        return sessionStorage.setItem(STORAGE_CURRENT_LANGUAGE, lang);
    }
    org = () => {
        return sessionStorage.getItem(STORAGE_CURRENT_ORG) || '';
    }
    setOrg = (lang: string) => {
        return sessionStorage.setItem(STORAGE_CURRENT_ORG, lang);
    }
    clear = () => {
        Cookies.remove(COOKIE_TOKEN_NAME);
        Cookies.remove(COOKIE_REFRESH_TOKEN_NAME);
    }
}

export const SessionService = new SessionServiceClass();
