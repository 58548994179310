import React, {Dispatch} from 'react';
import {ConfigurableVerticalTable, ConfigurableVerticalTableTypes} from "@spitchlingware/ui-reusable-components";
import {ConfigurableContent} from "@spitchlingware/ui-reusable-components/dist/components/Configurables/ConfigurableDetails/ConfigurableDetailsTypes";
import {withTranslation, WithTranslation} from "react-i18next";
import styleList from "./OrganizationDetailsTab.module.scss";
import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import { Action } from 'redux';
import {validateStringField} from "../../../../../utils/TableUtils";

type OwnProps = {};
type StateProps = {}
type DispatchProps = {};
type Props = ConfigurableContent & WithTranslation & OwnProps & StateProps & DispatchProps;
type State = {}


class OrganizationDetailsTab extends React.Component<Props, State> {

    helpPrefix = 'organizations';

    handleChange = (name: string, value: any) => {
        this.props.onChange(name, value);
    };

    detailsTableConfig = (): Array<ConfigurableVerticalTableTypes.ConfigurableVerticalTableConfigType> => {
        const {t} = this.props;
        return [
            {
                key: '_id',
                label: t(`${this.helpPrefix}.details.details_table.rows.id`),
                type: 'text',
                editable: false
            },
            {
                key: 'name',
                label: t(`${this.helpPrefix}.details.details_table.rows.name`),
                type: 'text',
                validate: validateStringField,
                editable: true
            },
            {
                key: 'disabled',
                label: t(`${this.helpPrefix}.details.details_table.rows.disabled.label`),
                type: 'checkbox',
                editable: true
            },
            {
                key: 'description',
                label: t(`${this.helpPrefix}.details.details_table.rows.description`),
                type: 'text',
                editable: true
            }
        ];
    };

    render() {
        const {element} = this.props;
        const {t} = this.props;
        const detailsConfig = this.detailsTableConfig();
        return (<div className={styleList.container}>
            <ConfigurableVerticalTable name={t(`${this.helpPrefix}.details.details_table.title`)}
                                       config={detailsConfig}
                                       onChange={this.handleChange}
                                       data={element}
                                       permissions={this.props.permissions}
            />
        </div>)
    };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (state: any, ownProps: OwnProps): StateProps => {
    return {}

};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch: Dispatch<Action<any>>, ownProps: OwnProps): DispatchProps => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrganizationDetailsTab))